export default {
  CLIENT: "Client",
  COINS_SOLD: "Coins Sold",
  PROCESSED_BUYS: "PROCESSED BUYS",
  PROCESSED_SELLS: "PROCESSED SELLS",
  COINS_PURCHASED: "COINS PURCHASED",
  COINS_SOLD_VOLUME: "COINS SOLD",
  FTD_STATS: "FTD STATS",
  ACTIVE_TODAY: "ACTIVE TODAY",
  ACTIVE_TOTAL: "ACTIVE TOTAL",
  REGISTRATIONS_TODAY: "REGISTRATIONS TODAY",
  REGISTRATIONS_TOTAL: "REGISTRATIONS TOTAL",
  DASHBOARD: "Dashboard",
  LOG_OUT: "Log out",
  LAST_UPDATE: "Last update",
  TIME_PERIOD: "Time Period",
  FROM_DATE: "From Date",
  TO_DATE: "To Date",
  FILTER: "Filter",
  CUSTOM: "Custom",
  TODAY: "Today",
  YESTERDAY: "Yesterday",
  THIS_MONTH: "This Month",
  LAST_MONTH: "Last Month",
  THIS_YEAR: "This Year",
  LAST_YEAR: "Last Year",
  GENERAL_STATISTICS: "GENERAL STATISTICS",
  FTDS: "FTDs",
  OF_LAST_MONTH: "of last month",
  ATTEMPTS: "Attempts",
  SUCCESSFUL: "Successful",
  AVG_VALUE: "Avg. Value",
  SUCCESS_RATE: "Success Rate",
  REQUESTS: "Requests",
  PENDING: "Pending",
  APPROVED: "Approved",
  VALUE: "Value",
  AVG_FTD: "Avg. FTD",
  FTD_VOLUME: "FTD Volume",
  USERS: "USERS",
  GENERATE_REPORT: "Generate Report",
  TECH_MASTER: "Tech Master",
  SUPER_ADMIN: "Super Admin",
  BASIC_USER: "Basic User",
  MERCHANT_MASTER: "Merchant Master",
  MERCHANT_BASIC: "Merchant Basic",
  MASTER_TECH: "Master Tech",
  MASTER_ADMIN: "Master Admin",
  MASTER_USER: "Master User",
  WL_ADMIN: "WL Admin",
  WL_USER: "WL User",
  MERCHANT_ADMIN: "Merchant Admin",
  MERCHANT_USER: "Merchant User",
  ADMIN: "Admin",
  USER: "User",
  REPORTS_DEPOSITS: "Deposits",
  REPORTS_GROSS_PROFIT: "Gross Profits",
  REPORTS_ACTIVITY: "Activity",
  MERCHANTS_VIEW: "View",
  MERCHANTS_EDIT: "Edit",
  ADMIN_PANEL_VIEW: "View",
  ADMIN_PANEL_EDIT: "Edit",
  DASHBOARD_VIEW: "View",
  MIDS_VIEW: "View",
  MIDS_EDIT: "Edit",
  ADMIN_PANEL: "ADMIN PANEL",
  MERCHANTS: "MERCHANTS",
  REPORTS: "REPORTS",
  MIDS: "MIDS",
  USER_MANAGEMENT: "USER MANAGEMENT",
  USER_VIEW: "View",
  USER_EDIT: "Edit",
  MY_ACCOUNT: "My Account",
  PAYOUT: "Payout"
};