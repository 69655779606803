import { FETCH_USER, FETCH_PERMISSIONS } from '../actions/types';

export default function(state = {}, action) {
  state.userUpdate = action.type;
  switch(action.type) {
  case FETCH_USER:
    return (action.payload);
  case FETCH_PERMISSIONS:
    state.access = action.payload;
    state.roleId = action.roleId;
    return state;
  default:
    return state;
  }
}