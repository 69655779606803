import React, { Component } from 'react';
import { Panel } from "react-bootstrap";

import Spinner from '../../../Spinner';
import Table from "../../Table";

import 'react-select/dist/react-select.css';
import '../../../../assets/css/apiData.css';

import { DASHBOARD_CUSTOMER_BALANCES_EVENT } from '../../../../actions/types';
const store =  require('../../../../reducers/index');

class DashBoardCustomerBalancesComponent extends Component {
  state = {
    general: undefined,
    loading: true,
    socket: false,
    columns: [{
      value: "country",
      label: "Country"
    }, {
      value: "accounts",
      label: "Transactions",
      isNumber: true,
      rightAligned: true
    }, {
      value: "avgDeposit",
      label: "Avg. Deposit",
      isNumber: true,
      rightAligned: true
    }, {
      value: "deposits",
      label: "Total",
      isNumber: true,
      rightAligned: true
    }]
  };

  subscribeFunction = null;

  componentDidMount(){
    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().dashboardReducer;

      if (state.update === "LOADING") {
        this.setState({
          loading: true
        });
      }

      if (state.update === DASHBOARD_CUSTOMER_BALANCES_EVENT && this.props.thisMonth && state.general.socket) {
        this.setState({ general: state.general, loading: false, socket: state.general.socket});
        return;
      }

      if (state.update === DASHBOARD_CUSTOMER_BALANCES_EVENT && !state.general.socket) {
        this.setState({ general: state.general, loading: false, socket: state.general.socket});
      }
    });
  };

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  tableData = () => {
    const { general } = this.state;

    if (!general) {
      return [];
    }

    return general.map(elem => {
      return {
        country: elem.country,
        isTotal: elem.country === "Total",
        accounts: elem.accounts,
        avgDeposit: elem.currency + parseFloat(elem.avgBalance).toFixed(2),
        deposits: elem.currency + parseFloat(elem.balance).toFixed(2)
      };
    });
  };

  render() {
    const { general, loading, columns } = this.state;

    let generalWrap = (
      <Panel>
        <Panel.Heading>
          <Panel.Title> TOTAL USD AVERAGES  </Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <Spinner smallContainer={ true } />
        </Panel.Body>
      </Panel>
    );

    if (!loading && general) {
      generalWrap = (
        <Panel style={ { height: "100%"} }>
          <Panel.Heading>
            <Panel.Title> TOTAL USD AVERAGES </Panel.Title>
          </Panel.Heading>
          <Panel.Body >
            <div className="panel-content table-content" style={ {overflow: "auto" } }>
              <Table
                columns={ columns }
                headerColor={ "#DFDFDF" }
                data={ this.tableData() }
                isStriped={ false }
              />
            </div>
          </Panel.Body>
        </Panel>
      );
    }

    return generalWrap;
  }

}

export default DashBoardCustomerBalancesComponent;