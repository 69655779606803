export const menuList = [{
  header: 'REPORTS',
  submenu: [{
    name: 'Generate Reports',
    url: '/generate-reports',
    access: 'REPORTS'
  }]
}, {
  header: 'SETTINGS',
  submenu: [{
    name: 'My Account',
    url: '/my-account',
    access: 'MY_ACCOUNT'
  }, {
    name: 'Bank Codes',
    url: '/bank-codes',
    access: 'BANKLIST'
  }]
}, {
  header: "SOFTWARE INTEGRATION",
  submenu: [{
    name: "API",
    url: "/docs",
    access: "API"
  }, {
    name: "Notes",
    url: "/notes",
    access: "NOTES"
  }]
}];
