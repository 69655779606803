export default {
  CLIENT: "用户",
  COINS_SOLD: "卖出金币",
  PROCESSED_BUYS: "已充值数量",
  PROCESSED_SELLS: "已提现数量",
  COINS_PURCHASED: "总充值额度",
  COINS_SOLD_VOLUME: "总提现额度",
  FTD_STATS: "首次充值数据",
  ACTIVE_TODAY: "今日用户数量",
  ACTIVE_TOTAL: "总用户数量",
  REGISTRATIONS_TODAY: "今日注册",
  REGISTRATIONS_TOTAL: "总注册",
  DASHBOARD: "操控板",
  LOG_OUT: "退出",
  LAST_UPDATE: "最新刷新",
  TIME_PERIOD: "时间周期",
  FROM_DATE: "日期开始",
  TO_DATE: "日期结束",
  FILTER: "选项",
  CUSTOM: "客户",
  TODAY: "今日",
  YESTERDAY: "昨日",
  THIS_MONTH: "本月",
  LAST_MONTH: "上个月",
  THIS_YEAR: "本年",
  LAST_YEAR: "去年",
  GENERAL_STATISTICS: "总统计",
  FTDS: "首次充值",
  OF_LAST_MONTH: "上个月",
  ATTEMPTS: "尝试次数",
  SUCCESSFUL: "成功率",
  AVG_VALUE: "平均额度",
  SUCCESS_RATE: "成功率",
  REQUESTS: "提现请求",
  PENDING: "提现等待",
  APPROVED: "提现成功",
  VALUE: "价值",
  AVG_FTD: "平均首次充值量",
  FTD_VOLUME: "首次充值量",
  USERS: "用户",
  TECH_MASTER: "Tech Master",
  SUPER_ADMIN: "Super Admin",
  BASIC_USER: "Basic User",
  MERCHANT_MASTER: "Merchant Master",
  MERCHANT_BASIC: "Merchant Basic",
  MASTER_TECH: "Master Tech",
  MASTER_ADMIN: "Master Admin",
  MASTER_USER: "Master User",
  WL_ADMIN: "WL Admin",
  WL_USER: "WL User",
  MERCHANT_ADMIN: "Merchant Admin",
  MERCHANT_USER: "Merchant User",
  ADMIN: "Admin",
  USER: "User",
  REPORTS_DEPOSITS: "Deposits",
  REPORTS_GROSS_PROFIT: "Gross Profits",
  REPORTS_ACTIVITY: "Activity",
  MERCHANTS_VIEW: "View",
  MERCHANTS_EDIT: "Edit",
  ADMIN_PANEL_VIEW: "View",
  ADMIN_PANEL_EDIT: "Edit",
  DASHBOARD_VIEW: "View",
  MIDS_VIEW: "View",
  MIDS_EDIT: "Edit",
  ADMIN_PANEL: "ADMIN PANEL",
  MERCHANTS: "MERCHANTS",
  REPORTS: "REPORTS",
  MIDS: "MIDS",
  USER_MANAGEMENT: "USER MANAGEMENT",
  USER_VIEW: "View",
  USER_EDIT: "Edit",
  MY_ACCOUNT: "My Account"
};