import React, {Component} from 'react';
import { Row, Column } from 'simple-flexbox';
import Datetime from 'react-datetime';
import DatePicker from 'react-datepicker';
import { Panel, Button, Table } from "react-bootstrap";
import Pagination from '@mui/material/Pagination';
import { saveAs } from 'file-saver';
import SweetAlert from 'sweetalert2-react';
import TextareaAutosize from 'react-textarea-autosize';

import ComponentHeader from "../../componentHeader";
import Dropdowns from '../apiData/dropdowns';
import Form from "../../../core/Form";
import Modal from '../Modal';
import Multiselect from "./customMultiselect";
import Select from "../Select";
import Spinner from '../../Spinner';

import "react-datepicker/dist/react-datepicker.css";
import "../../../assets/css/generateReports.css";
import '../../../assets/css/apiData.css';
import Calendar from '../../../assets/images/calendar.png';
import ReportIcon from '../../../assets/images/report.png';

import { getFormattedDate, convertUTCToLocalDate, convertLocalToUTCDate } from "../../../utils/utils.js";
import { FETCH_PERMISSIONS } from '../../../actions/types';
import { crudActions } from "../../../services/crudActions";

const INTEGER_MAX = 2147483647;

const store =  require('../../../reducers/index');

class GenerateReport extends Component {
  state = {
    controls: {
      timePeriod: {
        value:'thisMonth'
      },
      from: this.getPeriod(1).from,
      to: this.getPeriod(1).to
    },
    dateFieldDisabled: true,
    dropdowns: {
      periods: Dropdowns.periods
    },
    timeStamp: {
      fromDateTimeStamp: this.getPeriod(2).from,
      toDateTimeStamp: this.getPeriod(2).to
    },
    lastUpdate: Datetime.moment().utc().format('HH:mm - DD. MMM YYYY'),

    depositMethod: [],
    depositStatus: [],
    sortBy: [],
    sortByDate: [],
    extendWith: [],
    reportType: [],
    currency: [],
    baseCurrencies: [],
    displayMode: [],
    payoutMethod: [],
    payoutCountry: [],
    payoutStatus: [],
    settlementStatuses: [],
    countries: [],
    properties: [],
    transactionId: "",
    clientName: "",
    merchants: [],
    clientMids: [],
    clientPayoutMids: [],
    timeStampList:[],
    timeZones:[],

    reportFormData: {
      clientIds: [],
      depositMethod: [],
      depositStatus: [],
      currency: [],
      sortBy: "",
      reportType: "",
      displayMode: "",
      transactionId: "",
      sortByDate: "DAY",
      midIds: [],
      timeStampList: "",
      timeZone: ""
    },

    extendWithMap: {
      GROSS_PROFIT: {},
      RUNNING_BALANCE: {},
      SETTLEMENTS: {}
    },

    reportFormRules: [{
      name: "clientIds",
      type: "isArray",
      rules: {
        required: true
      }
    }, {
      name: "sortBy",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "reportType",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "timeStampList",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "timeZone",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "displayMode",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "sortByDate",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "currency",
      type: "isArray",
      rules: {
        min: 1,
        required: true
      }
    }, {
      name: "depositMethod",
      type: "isArray",
      rules: {
        min: 1,
        required: true
      }
    }, {
      name: "depositStatus",
      type: "isArray",
      rules: {
        min: 1,
        required: true
      }
    }, {
      name: "transactionId",
      type: "isPattern",
      value:"",
      rules: {
        trim: true,
        required: false,
        pattern: "^([0-9a-zA-z#?!@$%^&*-]+)((, +| +, +| + ,|, |,| ,| , )([0-9a-zA-z#?!@$%^&*-]+))*$"
      }
    }],

    reportForm: {},

    endpointGenerateMap: {
      GROSS_PROFIT: {
        LIST: "v1/reports/grossprofit/list",
        LIST_DETAILED: "v1/reports/grossprofit/list/detailed"
      },
      SERVER_LOGS: {
        LIST: "v1/reports/transactions"
      },
      TRANSACTIONS: {
        LIST: "v1/reports/clients/list"
      },
      PAYOUT: {
        LIST: "v1/reports/payout"
      },
      RUNNING_BALANCE: {
        LIST: "v1/reports/runningbalance/list"
      },
      SETTLEMENTS: {
        LIST: "v1/reports/settlements/list"
      },
      PERFORMANCE: {
        LIST: "v1/reports/performance"
      },
      DEPOSITS_PAYOUTS: {
        LIST: "v1/reports/clients/deposits_payouts"
      }
    },

    endpointXlsxMap: {
      GROSS_PROFIT: {
        LIST: "v1/reports/grossprofit/list/xlsx",
        LIST_DETAILED: "v1/reports/grossprofit/list/detailed/xlsx"
      },
      SERVER_LOGS: {
        LIST: "v1/reports/transactions/xlsx"
      },
      TRANSACTIONS: {
        LIST: "v1/reports/clients/xlsx"
      },
      PAYOUT: {
        LIST: "v1/reports/payout/xlsx"
      },
      RUNNING_BALANCE: {
        LIST: "v1/reports/runningbalance/list/xlsx"
      },
      SETTLEMENTS: {
        LIST: "v1/reports/settlements/list/xlsx"
      },
      PERFORMANCE: {
        LIST: "v1/reports/performance/xlsx"
      },
      DEPOSITS_PAYOUTS: {
        LIST: "v1/reports/clients/deposits_payouts/xlsx"
      }
    },

    defaultTimePeriods: {
      GROSS_PROFIT: {
        id: 2,
        value: 'thisMonth',
        label: 'This Month'
      },
      SERVER_LOGS: {
        id: 0,
        value: 'today',
        label: 'Today'
      },
      TRANSACTIONS: {
        id: 0,
        value: 'today',
        label: 'Today'
      },
      PAYOUT: {
        id: 0,
        value: 'today',
        label: 'Today'
      },
      RUNNING_BALANCE: {
        id: 2,
        value: 'thisMonth',
        label: 'This Month'
      },
      SETTLEMENTS: {
        id: 2,
        value: 'thisMonth',
        label: 'This Month'
      },
      PERFORMANCE: {
        id: 2,
        value: 'thisMonth',
        label: 'This Month'
      },
      DEPOSITS_PAYOUTS: {
        id: 0,
        value: 'today',
        label: 'Today'
      }
    },

    reportsGenerated: {},
    savedGeneratedReport: {},
    generatedReportType: "",
    
    modalData: "{}",
    modalType: "json",

    access: [],

    isLoading: false,
    isLoadingLookups: true,
    showSuccess: false,
    showError: false,
    errorMessage: "",
    successMessage: "",
    enableButton: false,

    isSortSettingsOpen: false,
    isExportSettingsOpen: false,
    isReportErrorFields: false,
    showModal: false,
    isModalLoading: false,
    currentPage: 1,
    rowsAmount: 100,
    clickButton:false,
    cnt: 0,
    periodId: 0 //today
  };

  sortNode = null;
  exportNode = null;

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    document.addEventListener("mousedown", this.handleClickOutside, false);
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside, false);
  };

  handleClickOutside = (event) => {
    if (this.sortNode && this.sortNode.contains(event.target)) {
      this.setState({
        isExportSettingsOpen: false
      });

      return;
    }

    if (this.exportNode && this.exportNode.contains(event.target)) {
      this.setState({
        isSortSettingsOpen: false
      });

      return;
    }

    this.setState({
      isExportSettingsOpen: false,
      isSortSettingsOpen: false,
      showSuccess: false,
      showError: false,
      errorMessage: "",
      showAlert: false,
      messageAlert: "",
      showInfo: false
    });
  };

  thisMonth = true;

  componentDidMount() {
    const { reportFormRules, reportFormData } = this.state;
    this.setState({
      reportForm: new Form(reportFormData, reportFormRules)
    }, this.loadLookupsData);

    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      this.setState({
        access: storeState.access
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          access: state.access
        });
      }
    });
  };

  loadLookupsData() {
    const { reportForm } = this.state;

    crudActions.get(`v1/clients/current`).then(
      data => {
        if (data) {
          this.setState({
            reportForm: Object.assign(reportForm, {
              clientIds: [data.id],
              midIds: data.mids || []
            }),
            clientName: data.name,
            merchants: {
              value: data.id,
              label: data.name
            },
            clientMids: data.mids || [],
            clientPayoutMids: data.payoutMids || []
          });
          
          crudActions.get(`v1/reports/lookups`).then(
            (lookups) => {
              if (lookups) {
                
                // Remove some options
                const sortByValues = lookups.sortBy;
                if (sortByValues && sortByValues['RUNNING_BALANCE']) {
                  sortByValues['RUNNING_BALANCE'] = sortByValues['RUNNING_BALANCE'].filter(x => !/account|provider/i.test(x.value));
                }
                
                // remove Activity report on FE, delete this after BE update
                const filtered = Object.assign(lookups, {
                  reportType: lookups.reportType.filter(type => type.value !== "ACTIVITY"),                  
                  sortBy: Object.assign(lookups.sortBy, {
                    PERFORMANCE: [{
                      value: "EMAIL",
                      label: "Email"
                    }, {
                      value: "IP",
                      label: "IP Address"
                    }]
                  }),
                  depositPayoutStatus: this.unionBy([lookups.depositStatus, lookups.payoutStatus], "value")
                });
                const reportType = lookups.reportType[0];
                this.setState({
                  ...filtered,
                  isLoadingLookups: false
                }, () => {
                  this.onValueChange(reportType, "reportType");
                });
              }
            }
          );
        }
      }
    );
  };

  unionBy = (arrays, iteratee) => {
    const map = {};

    arrays.forEach((array) => {
      array.forEach((object) => {
        map[object[iteratee]] = object;
      });
    });

    return Object.values(map);
  };

  preselectReportFields = (reportType) => {
    const { sortBy, currency, depositMethod, depositStatus, depositPayoutStatus, properties, settlementStatuses, defaultTimePeriods,
      baseCurrencies, payoutStatus, clientMids, clientPayoutMids, timeStampList, timeZones } = this.state;

    const displayMode = this.filterDisplayMode();
    const extendWith = this.filterExtendWith();
    const firstSortBy = sortBy[reportType] && sortBy[reportType][0];

    if (reportType === "TRANSACTIONS" || reportType === "SERVER_LOGS" || reportType === "PERFORMANCE" || reportType === "DEPOSITS_PAYOUTS") {
      this.onValueChange(depositMethod, "depositMethod");
    }

    if (reportType === "TRANSACTIONS" || reportType === "SERVER_LOGS" || reportType === "PERFORMANCE") {
      this.onValueChange(depositStatus, "depositStatus");
    }

    if (reportType === "DEPOSITS_PAYOUTS") {
      this.onValueChange(depositPayoutStatus, "depositStatus");
    }

    if (reportType === "TRANSACTIONS" || reportType === "PAYOUT" || reportType === "SERVER_LOGS" || reportType === "PERFORMANCE" || reportType === "DEPOSITS_PAYOUTS") {
      this.onValueChange(currency, "currency");
    }

    if (reportType === "PAYOUT") {
      this.onValueChange(payoutStatus, "payoutStatus");
      if (clientPayoutMids.length) {
        this.onValueChange([clientPayoutMids[0]], "midIds");
      }
      this.onValueChange(timeStampList[1], "timeStampList");
    }

    if (reportType === "GROSS_PROFIT") {
      this.onValueChange(baseCurrencies[0], "grossCurrency");
      this.onValueChange(properties[reportType], "properties");
    }

    if (reportType === "RUNNING_BALANCE") {
      const extendWithFirst = extendWith.filter(elem => elem.value !== firstSortBy.value)[0];
      this.onValueChange(extendWithFirst, "extendWith");
      if (clientMids.length) {
        //this.onValueChange(clientMids[0], "midIds");
      }
    }

    if (reportType === "SETTLEMENTS") {
      const extendWithFirst = extendWith.filter(elem => elem.value !== firstSortBy.value)[0];
      this.onValueChange(extendWithFirst, "extendWith");
      this.onValueChange(settlementStatuses, "settlementStatus");
    }

    if(reportType === "TRANSACTIONS" || reportType === "PERFORMANCE" || reportType === "DEPOSITS_PAYOUTS" || reportType === "RUNNING_BALANCE" || reportType === "PAYOUT"){
      const enabledMids = this.getEnabledMids();
      const defaultTimeZone = timeZones.find(zone => zone.value === "UTC");
      this.onValueChange(enabledMids, "midIds");
      this.onValueChange(timeStampList[1], "timeStampList");
      this.onValueChange(defaultTimeZone, "timeZone");
    }

    this.onValueChange(displayMode[0], "displayMode");
    this.onValueChange(firstSortBy, "sortBy");
    this.onSelectPeriodChange(defaultTimePeriods[reportType], "timePeriod");
  };

  isDateValid = (current, state) => {
    const { timeStamp, fromDate = timeStamp.fromDateTimeStamp, toDate = timeStamp.toDateTimeStamp, reportForm, timeZones } = this.state;
    let timeZone = "00:00";
    if (reportForm.timeZone) {
      timeZone = timeZones.find(zone => zone.value === reportForm.timeZone).offset;
    }
    const yesterday = Datetime.moment().utc().zone(timeZone).subtract(1, 'days');

    if (state === 'fromDateTimeStamp') {
      return Datetime.moment(current).isBefore(!fromDate ? yesterday : toDate);
    }

    return Datetime.moment(current).isAfter(!toDate ? yesterday : Datetime.moment(fromDate).subtract(1, 'days'));
  };

  getPeriod(periodType) {
    let timeZone = "00:00";
    if (this.state) {
      if (this.state.reportForm.timeZone) {
        timeZone = this.state.timeZones.find(zone => zone.value === this.state.reportForm.timeZone).offset;
      }
    }

    const moment = Datetime.moment;

    switch (periodType) {
    case 1: // yesterday
      return {
        from: moment().utc().zone(timeZone).subtract(1, 'day').hour(0).minute(0).second(0),
        to: moment().utc().zone(timeZone).subtract(1, 'day').hour(23).minute(59).second(59)
      };
    case 2: // this month
      return {
        from: moment().utc().zone(timeZone).year(moment().utc().zone(timeZone).year()).month(moment().utc().zone(timeZone).month()).date(1).hour(0).minute(0).second(0),
        to: moment().utc().zone(timeZone)
      };
    case 3: // last month
      return {
        from: moment().utc().zone(timeZone).year(moment().utc().zone(timeZone).year()).month(moment().utc().zone(timeZone).month() - 1).date(1).hour(0).minute(0).second(0),
        to: moment().utc().zone(timeZone).year(moment().utc().zone(timeZone).year()).month(moment().utc().zone(timeZone).month() - 1)
          .date(moment().utc().zone(timeZone).month(moment().utc().zone(timeZone).month() - 1).daysInMonth()).hour(23).minute(59).second(59)
      };
    case 4: // this year
      return {
        from: moment().utc().zone(timeZone).year(moment().utc().zone(timeZone).year()).month(0).date(1).hour(0).minute(0).second(0),
        to: moment().utc().zone(timeZone)
      };
    case 5: // last year
      return {
        from: moment().utc().zone(timeZone).year(moment().utc().zone(timeZone).year() - 1).month(0).date(1).hour(0).minute(0).second(0),
        to: moment().utc().zone(timeZone).year(moment().utc().zone(timeZone).year() - 1).month(11).date(31).hour(23).minute(59).second(59)
      };
    case 6: // last hour
      return {
        from: moment().utc().zone(timeZone).subtract(1, 'hours'),
        to: moment().utc().zone(timeZone)
      };
    case 7: // last 6 hours
      return {
        from: moment().utc().zone(timeZone).subtract(6, 'hours'),
        to: moment().utc().zone(timeZone)
      };
    case 0: // today
    default: // custom period
      return {
        from: moment().utc().zone(timeZone).hour(0).minute(0).second(0),
        to: moment().utc().zone(timeZone)
      };
    }
  };

  onSelectPeriodChange = (value, id) => {
    const timeStamp = {...this.state.timeStamp};
    const controls = {...this.state.controls};
    value = value === null ? {
      id: 0,
      value: 'today',
      label: 'Today'
    } : value;
    const dateFieldDisabled = value.id > 0 && value.id <= 7; // depends on any predefined period (today, yesterday etc.)
    const period = this.getPeriod(value.id);

    controls[id] = value;
    timeStamp.fromDateTimeStamp = period.from;
    timeStamp.toDateTimeStamp = period.to;
    controls.from = getFormattedDate(timeStamp.fromDateTimeStamp._d);
    controls.to = getFormattedDate(timeStamp.toDateTimeStamp._d);

    //if this month
    if (value.id === 2) {
      this.thisMonth = true;
    } else {
      this.thisMonth = false;
    }

    this.setState({
      controls: controls,
      dateFieldDisabled: dateFieldDisabled,
      timeStamp: timeStamp,
      currentPage: 1,
      periodId: value.id
    });
  };

  handleDateChange = (event, state, fieldState) => {
    const controls = {...this.state.controls};
    const timeStamp = {...this.state.timeStamp};

    timeStamp[state] = event;
    controls[fieldState] = event;

    this.setState({controls: controls, timeStamp: timeStamp, isSubmitting: false});
  };

  onExportClick = () => {
    const { reportForm, timeStamp, timeZones, endpointXlsxMap } = this.state;
    const reportFormData = reportForm.data();

    this.setState({
      isExportSettingsOpen: false
    });

    const reportType = reportFormData.reportType;
    const displayMode = reportFormData.displayMode;

    let dateFormat = "DD.MM.YYYY HH:mm:ss";
    if (reportType === "SETTLEMENTS" || reportType === "GROSS_PROFIT") {
      dateFormat = "DD.MM.YYYY";
    }

    let timeZone = "00:00";
    if (reportForm.timeZone) {
      timeZone = timeZones.find(zone => zone.value === reportForm.timeZone).offset;
    }

    const fromDateTimeStamp = Datetime.moment(timeStamp.fromDateTimeStamp).utc().zone(timeZone).format(dateFormat);
    const toDateTimeStamp = Datetime.moment(timeStamp.toDateTimeStamp).utc().zone(timeZone).format(dateFormat);

    crudActions.post(endpointXlsxMap[reportType][displayMode], Object.assign(reportFormData, {
      fromDate: fromDateTimeStamp,
      toDate: toDateTimeStamp,
      pageNumber: 1,
      limit: INTEGER_MAX
    })).then(
      response => {
        if (response) {
          response.blob().then(
            result => {
              const dateTime = Datetime.moment().format("DD-MM-YYYY HH-mm-ss");
              saveAs(result, `${dateTime} - ${reportType}.xlsx`);
            }
          );
        }
      }
    ).catch(
      err => {
        this.setState({
          showError: true,
          errorMessage: err.message
        });
      }
    );
  };

  defineReportForm = (reportType) => {
    const { reportForm, reportFormRules, reportFormData, timeStampList  } = this.state;

    const copyArray = (arr) => {
      return JSON.parse(JSON.stringify(arr));
    };

    const payoutForm = new Form ({
      clientIds: reportForm.clientIds,
      payoutStatus: [],
      currency: [],
      sortBy: "",
      reportType: reportType,
      displayMode: "",
      sortByDate: reportForm.sortByDate,
      transactionId: "",
      midIds: [],
      timeZone: "",
      timeStampList:  timeStampList
    }, copyArray(reportFormRules.slice(0, 6)).concat([{
      name: "midIds",
      type: "isArray",
      rules: {
        required: true
      }
    }, {
      name: "payoutStatus",
      type: "isArray",
      rules: {
        required: true,
        min: 1
      }
    }, {
      name: "transactionId",
      type: "isPattern",
      rules: {
        trim: true,
        required: false,
        pattern: "^([0-9a-zA-z#?!@$%^&*-]+)((, +| +, +| + ,|, |,| ,| , )([0-9a-zA-z#?!@$%^&*-]+))*$"
      }
    }]));

    const grossProfitForm = new Form({
      clientIds: [],
      reportType: reportType,
      displayMode: "",
      sortBy: "",
      sortByDate: reportForm.sortByDate,
      properties: [],
      grossCurrency: ""
    }, copyArray(reportFormRules.slice(0, 4)).concat(
      [{
        name: "properties",
        type: "isArray",
        rules: {
          min: 1,
          required: true
        }
      }, {
        name: "grossCurrency",
        type: "isString",
        rules: {
          required: true
        }
      }]
    ));

    const transactionsForm = new Form(
      Object.assign({}, reportFormData, {
        clientIds: reportForm.clientIds,
        displayMode: "",
        reportType: reportType,
        midIds: reportForm.midIds || [],
        timeStampList:  timeStampList,
        timeZone: "",
      }, reportType === "SERVER_LOGS" ? { orderId: "" } : {}),
      reportType !== "SERVER_LOGS" ? copyArray(reportFormRules) : copyArray(reportFormRules).concat([{
        name: "orderId",
        type: "isString",
        rules: {
          required: false
        }
      }, {
        name: "midIds",
        type: "isArray",
        rules: {
          min: 1,
          required: true
        }
      }, ])
    );

    const runningBalanceForm = new Form(
      {
        clientIds: reportForm.clientIds,
        reportType: reportType,
        displayMode: "",
        sortBy: "",
        properties: [],
        midIds: [],
        currency: [],
        extendWithFilter: [],
        removeZeroValues: false,
        sortByDate: "DAY",
        timeZone: ""
      }, copyArray(reportFormRules.slice(0, 4)).concat(
        [{
          name: "extendWithFilter",
          type: "isArray",
          rules: {
            min: 1,
            required: true
          }
        }, {
          name: "currency",
          type: "isArray",
          rules: {
            required: true,
            min: 1
          }
        }, {
          name: "midIds",
          type: "isArray",
          rules: {
            required: true,
            min: 1
          }
        }, {
          name: "properties",
          type: "isArray",
          rules: {
            required: false
          }
        }]
      )
    );

    const settlementsForm = new Form(
      {
        clientIds: reportForm.clientIds,
        reportType: reportType,
        displayMode: "",
        sortBy: "",
        properties: [],
        currency : [],
        midIds: [],
        invoiceNumber: "",
        settlementStatus: [],
        removeZeroValues: false,
        sortByDate: "DAY"
      }, copyArray(reportFormRules.slice(0, 4)).concat(
        [{
          name: "extendWithFilter",
          type: "isArray",
          rules: {
            min: 1,
            required: true
          }
        }, {
          name: "settlementStatus",
          type: "isArray",
          rules: {
            min: 1,
            required: true
          }
        }, {
          name: "properties",
          type: "isArray",
          rules: {
            required: false
          }
        }, {
          name: "currency",
          type: "isArray",
          rules: {
            required: true,
            min: 1
          }
        }, {
          name: "midIds",
          type: "isArray",
          rules: {
            required: true,
            min: 1
          }
        }, {
          name: "invoiceNumber",
          type: "isPattern",
          rules: {
            required: false,
            pattern: "^[0-9]+$"
          }
        }]
      )
    );

    const performanceForm = new Form(
      Object.assign({}, reportFormData, {
        clientIds: reportForm.clientIds,
        displayMode: "",
        reportType: reportType,
        timeZone: "",
        timeStampList: timeStampList,
        email: "",
        ip: ""
      }),
      copyArray(reportFormRules).concat(
        {
          name: "email",
          type: "isString",
          rules: {
            required: false
          }
        },
        {
          name: "ip",
          type: "isString",
          rules: {
            required: false
          }
        }
      )
    );

    const depositsPayoutsForm = new Form(
      Object.assign({}, reportFormData, {
        clientIds: reportForm.clientIds,
        displayMode: "",
        reportType: reportType,
        midIds: reportForm.midIds || [],
        timeStampList:  timeStampList,
        timeZone: "",
      }),
      reportType !== "SERVER_LOGS" ? copyArray(reportFormRules).filter(elem => elem.name !== "transactionId") : copyArray(reportFormRules).filter(elem => elem.name !== "transactionId").concat([{
        name: "orderId",
        type: "isString",
        rules: {
          required: false
        }
      }, {
        name: "midIds",
        type: "isArray",
        rules: {
          min: 1,
          required: true
        }
      }, {
        name: "transactionId",
        type: "isString",
        rules: {
          required: false
        }
      }])
    );

    if (reportType !== "SERVER_LOGS") {
      delete transactionsForm.orderId;
    }

    if (reportType === "GROSS_PROFIT") {
      return grossProfitForm;
    } else if (reportType === "PAYOUT") {
      return payoutForm;
    } else if (reportType === "RUNNING_BALANCE") {
      return runningBalanceForm;
    } else if (reportType === "SETTLEMENTS") {
      return settlementsForm;
    } else if (reportType === "PERFORMANCE") {
      return performanceForm;
    } else if (reportType === "DEPOSITS_PAYOUTS") {
      return depositsPayoutsForm;
    } else {
      return transactionsForm;
    }
  };

  mapMerchants = () => {
    const { reportForm, merchants } = this.state;

    const field = reportForm["clientIds"];

    return field.map(elem => {
      return merchants.find(lookupElem => elem === lookupElem.value);
    });
  };

  onValueChange = (newValue, fieldName) => {
    let { reportForm } = this.state;
    const { clientMids, defaultTimePeriods, isReportErrorFields, extendWithMap } = this.state;
    if (typeof newValue === "boolean") {
      reportForm = Object.assign(reportForm, {
        [fieldName]: !newValue
      });
    } else if (newValue.value) {
      reportForm = Object.assign(reportForm, {
        [fieldName]: fieldName === "midIds" || fieldName === "currency" ? [newValue.value] : newValue.value
      });
    } else if (newValue.target) {
      reportForm = Object.assign(reportForm, {
        [fieldName]: newValue.target.value
      });
    } else {
      reportForm = Object.assign(reportForm, {
        [fieldName]: newValue.map(elem => elem.value)
      });
    }
    if (isReportErrorFields) {
      reportForm.isFormValid();
    }

    this.setState({
      currentPage: 1
    });

    const reportType = reportForm.reportType;
    const extendWithFilterField = reportForm.fieldRules.find(rule => rule.name === "extendWithFilter");
    const extendWithReport = extendWithMap[reportType];
    const extendWith = reportForm.extendWith;
    const extendWithField = reportForm.fieldRules.find(rule => rule.name === "extendWith");

    if ((fieldName === "extendWith" || fieldName === "sortBy") && extendWithReport) {
      const notRequired = !extendWithReport[extendWith] || !extendWith;
      extendWithFilterField.rules.min = notRequired ? 0 : 1;
      reportForm = Object.assign(reportForm, {
        extendWith: extendWith === reportForm.sortBy ? "" : extendWith,
        extendWithFilter: [],
      });
    } else if (fieldName === "displayMode") {
      if (extendWithFilterField && extendWithField) {
        const isListDetailed = reportForm[fieldName] === "LIST_DETAILED";
        extendWithField.rules.min = isListDetailed ? 1 : 0;
        extendWithField.rules.required = isListDetailed;
        extendWithFilterField.rules.min = isListDetailed && extendWithReport[extendWith] ? 1 : 0;
      }
    } else if (fieldName === "midIds" && reportType === "RUNNING_BALANCE") {
      reportForm.currency = [clientMids.find(elem => reportForm.midIds.includes(elem.value)).currency];
    } else if (fieldName === "currency" && reportType === "RUNNING_BALANCE") {
      reportForm.midIds = [];
    } else if (fieldName === "clientIds" && (reportType === "TRANSACTIONS" || reportType === "PERFORMANCE" || reportType === "DEPOSITS_PAYOUTS")) {
      reportForm.midIds = [];
    } else if (fieldName === "timeZone" && (reportType === "RUNNING_BALANCE" || reportType === "PAYOUT" || reportType === "TRANSACTIONS" || reportType === "DEPOSITS_PAYOUTS")) {
      const currentPeriod = this.state.dropdowns.periods.find(elem => elem.id === this.state.controls.timePeriod.id);
      this.onSelectPeriodChange(currentPeriod, "timePeriod");
    }

    const isReportType = fieldName === "reportType";
    if (isReportType) {
      this.onSelectPeriodChange(defaultTimePeriods[reportType], "timePeriod");
    }

    this.setState({
      reportForm: isReportType ? this.defineReportForm(reportType) : reportForm
    }, () => {
      if (isReportType) {
        this.preselectReportFields(reportType);
      }
    });

    if (fieldName === "sortByDate") {
      this.setState({
        isSortSettingsOpen: false
      });
    }
  };

  getFields = () => {
    const { reportForm, sortBy, currency, extendWithMap, clientPayoutMids, merchants,
      payoutStatus, depositMethod, depositStatus, depositPayoutStatus, properties, settlementStatuses } = this.state;

    const extendWithReportMap = extendWithMap[reportForm.reportType];
    const extendWithValue = extendWithReportMap ? extendWithReportMap[reportForm.extendWith] : "";

    let extendWithFilterItems = [];
    let extendWithFilterSelectedItems = [];
    if (extendWithValue) {
      extendWithFilterItems = extendWithValue.value === "merchants" ? merchants : this.state[extendWithValue.value];
      extendWithFilterSelectedItems = this.mapExtendWithFilter(extendWithValue.value);
    }

    const baseFields = [{
      id: "displayMode",
      type: "select",
      label: "Display Mode",
      options: this.filterDisplayMode()
    }, {
      id: "sortBy",
      type: "select",
      label: "Sort By",
      disabled: !reportForm.reportType,
      options: reportForm.reportType ? sortBy[reportForm.reportType] : []
    }, {}, {}];

    if (reportForm.reportType === "GROSS_PROFIT" && reportForm.displayMode === "LIST_DETAILED") {
      baseFields[baseFields.length - 1] = {
        id: "extendWith",
        type: "select",
        label: "Extend with",
        disabled: false,
        options: this.filterExtendWith()
      };
    }

    const buttonField = [{
      id: "button",
      type: "button"
    }];

    const reportFields = {
      TRANSACTIONS: [
        [{
          id: "currency",
          type: "multiselect",
          label: "Currency",
          selectedItems: this.mapSelectedItems("currency", "currency"),
          items: currency,
        }, {
          id: "depositMethod",
          type: "multiselect",
          label: "Payment Method",
          selectedItems: this.mapSelectedItems("depositMethod", "depositMethod"),
          items: depositMethod
        }, {
          id: "depositStatus",
          type: "multiselect",
          label: "Status",
          selectedItems: this.mapSelectedItems("depositStatus", "depositStatus"),
          items: depositStatus
        },{
          id: "midIds",
          type: "multiselect",
          label: "MIDs",
          withId: true,
          selectedItems: this.mapSelectedItems("midIds", "midIds"),
          items: this.getMidCurrencies(),
        },{}],
        [{
          id: "transactionId",
          type: "textarea",
          label: "Search Transaction ID",
        }, {}, {
          id:"bulkButton",
          type:"bulkButton"
        }],
      ],
      GROSS_PROFIT: [
        [{
          id: "properties",
          type: "multiselect",
          label: "Properties",
          selectedItems: this.mapSelectedItems("properties", "properties", true),
          items: reportForm.reportType ? properties[reportForm.reportType] : []
        }, {
          id: "extendWithFilter",
          type: "multiselect",
          notShowOnCondition: !extendWithValue,
          label: extendWithValue ? extendWithValue.label : "",
          selectedItems: extendWithFilterSelectedItems,
          items: extendWithFilterItems
        }, {}]
      ],
      SERVER_LOGS: [
        [{
          id: "currency",
          type: "multiselect",
          label: "Currency",
          selectedItems: this.mapSelectedItems("currency", "currency"),
          items: currency,
        }, {
          id: "depositMethod",
          type: "multiselect",
          label: "Payment Method",
          selectedItems: this.mapSelectedItems("depositMethod", "depositMethod"),
          items: depositMethod
        }, {
          id: "depositStatus",
          type: "multiselect",
          label: "Status",
          selectedItems: this.mapSelectedItems("depositStatus", "depositStatus"),
          items: depositStatus
        }, {}],
        [{
          id: "transactionId",
          type: "textarea",
          label: "Search Transaction ID"
        }, {}, {
          id: "orderId",
          type: "input",
          label: "Search Order ID"
        }]
      ],
      PAYOUT: [
        [{
          id: "midIds",
          type: "select",
          label: "Payout MID",
          options: clientPayoutMids,
        }, {
          id: "payoutStatus",
          type: "multiselect",
          label: "Payout Status",
          selectedItems: this.mapSelectedItems("payoutStatus", "payoutStatus"),
          items: payoutStatus
        }, {
          id: "transactionId",
          type: "textarea",
          label: "Search Transaction ID"
        }, {}],
        [{}, {}, { 
          id:"bulkButton",
          type:"bulkButton"
        }]
      ],
      RUNNING_BALANCE: [
        [{
          id: "currency",
          type: "select",
          label: "Currency",
          disabled: false,
          options: this.runningBalanceCurrencies()
        }, {
          id: "midIds",
          type: "multiselect",
          label: "MIDs",
          withId: true,
          selectedItems: this.mapMids(),
          items: this.getMidsByCurrency()
        }, {}, {}],
        [{}, {}, {}]
      ],
      SETTLEMENTS: [
        [{
          id: "currency",
          type: "multiselect",
          label: "Currency",
          selectedItems: this.mapSelectedItems("currency", "currency"),
          items: currency,
        }, {
          id: "settlementStatus",
          type: "multiselect",
          label: "Statuses",
          selectedItems: this.mapSelectedItems("settlementStatus", "settlementStatuses"),
          items: settlementStatuses
        }, {
          id: "midIds",
          type: "multiselect",
          label: "MIDs",
          withId: true,
          selectedItems: this.mapMids(),
          items: this.getMidsByCurrency()
        }, {}],
        [{
          id: "invoiceNumber",
          type: "input",
          label: "Search Invoice #"
        }, {}, {}]
      ],
      PERFORMANCE: [
        [{
          id: "email",
          type: "textarea",
          label: "Search Email"
        }, {
          id: "ip",
          type: "textarea",
          label: "Search IP Address"
        }, {
          id: "bulkButton",
          type: "bulkButton"
        }]
      ],
      DEPOSITS_PAYOUTS: [
        [{
          id: "currency",
          type: "multiselect",
          label: "Currency",
          selectedItems: this.mapSelectedItems("currency", "currency"),
          items: currency,
        }, {
          id: "depositMethod",
          type: "multiselect",
          label: "Payment Method",
          selectedItems: this.mapSelectedItems("depositMethod", "depositMethod"),
          items: depositMethod
        }, {
          id: "depositStatus",
          type: "multiselect",
          label: "Status",
          selectedItems: this.mapSelectedItems("depositStatus", "depositStatus"),
          items: depositPayoutStatus
        }, {}],
        [{
          id: "midIds",
          type: "multiselect",
          label: "MIDs",
          withId: true,
          selectedItems: this.mapSelectedItems("midIds", "midIds"),
          items: this.getMidCurrencies()
        }, {
          id: "transactionId",
          type: "textarea",
          label: "Search"
        }, {}, {}],
        [{}, {}, {
          id: "bulkButton",
          type: "bulkButton"
        }]
      ]
    };

    const buildFields = (type) => {
      const fields = reportFields[type];
      fields.splice(0, 0, baseFields);
      fields[fields.length - 1] = fields[fields.length - 1].concat(buttonField);

      return fields;
    };

    const selectedReportFields = reportFields[reportForm.reportType];

    if (!selectedReportFields) {
      return buildFields('TRANSACTIONS');
    }

    return buildFields(reportForm.reportType);
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      historyJson: {}
    });
  };

  getReportColumnIndex = (headerId) => {
    const { reportsGenerated } = this.state;
    return reportsGenerated.headers.map(e => e.headerId).indexOf(headerId);
  };

  onOpenModalClick = (data, type) => {
    if (type === "json" && (data === "{}" || !data)) {
      return;
    }

    try {
      const modalData = type === "json" ? JSON.parse(data) : data;

      this.setState({
        showModal: true,
        modalType: type,
        modalData
      });
    } catch {
      this.setState({
        errorMessage: type === "json" ? "Parsing logs failed." : "Getting notes failed.",
        showError: true,
        alertType: "error"
      });
    }
  };

  onGenerateReport = () => {
    const { reportForm, timeStamp, timeZones, endpointGenerateMap, currentPage, rowsAmount, periodId } = this.state;
    const isReportFormValid = reportForm.isFormValid();
    this.setState({
      reportForm: reportForm,
      isReportErrorFields: true,
      clickButton: true
    });

    if(reportForm.transactionId){
      this.setState({
        enableButton: true
      });
    }

    if (isReportFormValid) {
      this.setState({
        isLoading: true
      });

      const reportFormData = reportForm.data();
      
      const displayMode = reportFormData.displayMode;
      const reportType = reportFormData.reportType;
      if (reportType === "SERVER_LOGS" || reportType === "TRANSACTIONS" || reportType === "PAYOUT" || reportType === "DEPOSITS_PAYOUTS") {
        reportFormData.transactionId = reportFormData.transactionId.trim();
      }
      //Is it necessary?
      delete reportFormData.sortByDate;

      let dateFormat = "DD.MM.YYYY HH:mm:ss";
      if (reportType === "SETTLEMENTS" || reportType === "GROSS_PROFIT") {
        dateFormat = "DD.MM.YYYY";
      }
      if (reportType === "SETTLEMENTS") {
        delete reportFormData.currency;
      }

      const period = this.getPeriod(periodId);

      if (periodId !== 8) {
        //we do not override dates for custom date picker
        timeStamp.fromDateTimeStamp = period.from;
        timeStamp.toDateTimeStamp = period.to;
      }

      let timeZone = "00:00";
      if (reportForm.timeZone) {
        timeZone = timeZones.find(zone => zone.value === reportForm.timeZone).offset;
      }

      const fromDateTimeStamp = Datetime.moment(timeStamp.fromDateTimeStamp).utc().zone(timeZone).format(dateFormat);
      const toDateTimeStamp = Datetime.moment(timeStamp.toDateTimeStamp).utc().zone(timeZone).format(dateFormat);

      crudActions.post(endpointGenerateMap[reportType][displayMode], Object.assign(reportFormData, {
        fromDate: fromDateTimeStamp,
        toDate: toDateTimeStamp,
        pageNumber: currentPage,
        limit: rowsAmount
      })).then(
        (reportsGenerated) => {
          if (reportsGenerated) {
            if (displayMode === "LIST_DETAILED") {
              reportsGenerated.isListDetailed = true;
              reportsGenerated.data = reportsGenerated.data.reduce(
                (finalArray, currentElem) => {
                  finalArray.push({
                    mainRow: true,
                    content: currentElem.mainRow.concat(Array(reportsGenerated.headers.length - currentElem.mainRow.length).fill(""))
                  });

                  finalArray = finalArray.concat(currentElem.subRows.map(elem => {
                    return {
                      mainRow: false,
                      content: Array(reportsGenerated.headers.length - elem.length).fill("").concat(elem)
                    };
                  }));

                  return finalArray;
                }, []
              );
            } else if (
              reportType === "SERVER_LOGS" || reportType === "TRANSACTIONS" ||
              reportType === "PAYOUT" || reportType === "PERFORMANCE" ||
              reportType === "DEPOSITS_PAYOUTS"
            ) {
              reportsGenerated.isHistory = true;
            } else {
              reportsGenerated.isList = true;
            }
            if(reportsGenerated.warningMessage){
              this.setState({
                showAlert: true,
                messageAlert : reportsGenerated.warningMessage,
              });
            }
            this.setState({
              reportsGenerated: reportsGenerated,
              generatedReportType: reportType,
              savedGeneratedReport: reportFormData,
              isLoading: false,
              cnt: reportsGenerated.cnt,
              lastUpdate: Datetime.moment().utc().format('HH:mm - DD. MMM YYYY')
            });
          }
        }
      ).catch(
        err => {
          if (err && err.message) {
            this.setState({
              errorMessage: err.message,
              showError: true
            });
          }
        }
      );
    } else {
      this.setState({
        showError: true,
        errorMessage: "Some fields have errors. Please, fix them in order to continue."
      });
    }
  };

  onConfirm = () => {
    this.setState({
      showSuccess: false,
      showError: false,
      errorMessage: "",
      successMessage: "",
      showAlert: false,
      messageAlert: "",
      showInfo: false,
      infoMessage: ""
    });
  }

  onSortByOpen = () => {
    this.setState({
      isSortSettingsOpen: !this.state.isSortSettingsOpen
    });
  };

  onExportOpen = () => {
    this.setState({
      isExportSettingsOpen: !this.state.isExportSettingsOpen
    });
  };
  
  getMidCurrencies = () => {
    const {clientMids} = this.state;
    if (!clientMids) {
      return [];
    }

    return clientMids;
  };

  mapSelectedItems = (type, lookupsName, useReportType) => {
    const { reportForm } = this.state;
    const field = reportForm[type];
    const { reportType } = reportForm;
    let lookup = null;
    if (lookupsName === "midIds") {
      lookup = this.getMidCurrencies();
    } else if (useReportType) {
      lookup = this.state[lookupsName][reportType];
    } else {
      if (type === "depositStatus" && reportType === "DEPOSITS_PAYOUTS") {
        lookup = this.state.depositPayoutStatus;
      } else {
        lookup = this.state[lookupsName];
      }
    }
    if (!field || !lookup || !Array.isArray(field) || field.some(elem => !lookup.find(lookupElem => elem === lookupElem.value))) {
      return [];
    }
    return field.map(elem => {
      return lookup.find(lookupElem => elem === lookupElem.value);
    });
  };

  runningBalanceCurrencies = () => {
    const {clientMids} = this.state;

    if (!clientMids) {
      return [];
    }
    const findCurrency = clientMids.map(mid => mid.currency);

    const setData = new Set(findCurrency);
    const uniqueCurrencyArray = Array.from(setData);

    const uniqueCurrencyObj = uniqueCurrencyArray.map(currency => {
      const obj = {};
      obj.value = currency;
      obj.label = currency;
      return obj;
    });

    return uniqueCurrencyObj;
  };

  mapMids = () => {
    const { reportForm } = this.state;

    if (!reportForm.midIds || !reportForm.midIds.length) {
      return [];
    }

    const mids = this.getMidCurrencies();
    if (!mids || !mids.length) {
      return [];
    }

    const mappedMids = reportForm.midIds.map(elem => {
      return mids.find(mid => mid.value === elem);
    });

    return mappedMids.filter(mid => !!mid);
  };

  getMidsByCurrency = () => {
    const { reportForm } = this.state;
    const {clientMids} = this.state;
    if (!clientMids) {
      return [];
    }

    const selectedCurrency = reportForm.currency && reportForm.currency.length > 0 ? reportForm.currency[0] : null;       
    const field = reportForm.midIds || [];
    const lookup = selectedCurrency != null ? clientMids.filter(mid => reportForm.currency.includes(mid.currency)) : [];
        
    if (field.length === 0) {
      lookup.forEach(item => {
        field.push(item.value);
      });
    }

    return lookup;
  };

  mapExtendWithFilter = (type) => {
    const { reportForm } = this.state;
    const field = reportForm["extendWithFilter"];
    const lookup = this.state[type];

    return field.map(elem => {
      return lookup.find(lookupElem => elem === lookupElem.value);
    });
  };

  getEnabledMids = () => {
    const midIds = this.getMidCurrencies();
    if (!midIds) {
      return [];
    }
    return midIds.filter(mid => mid.enabled);
  };

  checkAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);
    if (!foundPermission) {
      return false;
    }
    return foundPermission.state;
  };

  filterDisplayMode = () => {
    const { displayMode, reportForm } = this.state;

    if (reportForm.reportType === "TRANSACTIONS" || reportForm.reportType === "PAYOUT" ||
      reportForm.reportType === "RUNNING_BALANCE" || reportForm.reportType === "SETTLEMENTS") {
      return displayMode.filter(elem => elem.value === "LIST");
    }

    return displayMode;
  };

  filterExtendWith = () => {
    const { reportForm, extendWith } = this.state;
    const { reportType, sortBy } = reportForm;
    let extendWithValues = [];

    if (!extendWith[reportType]) {
      return [];
    }

    if(reportType === "SETTLEMENTS"){
      extendWithValues = extendWith[reportType].filter(elem => elem.value !== "MIDS");
    } else {
      extendWithValues = extendWith[reportType].filter(elem => elem.value !== sortBy);
    }

    return extendWithValues;
  };

  isRightAligned = (index) => {
    const { reportsGenerated } = this.state;
    return reportsGenerated.headers[index].headerType === "NUMBER";
  };

  triggerButtonEvent = (headers, headerIndex, row, tableData, tableDataType) => {
    const { savedGeneratedReport } = this.state;
    const filterHeders = this.filterHeaders(headers);
  
    const buttonSettings = filterHeders.at(headerIndex);
  
    const headersIndexes = headers.filter(elem => buttonSettings.fields.includes(elem.headerId)).map(
      elem => headers.findIndex(header => header.headerId === elem.headerId)
    );

    const data = {};

    headersIndexes.forEach(
      elem => {
        const foundHeader = headers[elem];
        data[foundHeader.headerId] = row[elem];
      }
    );

    if (buttonSettings.endpoint.includes("v1/report-buttons/transactions")) {
      this.setState({
        isModalLoading: true,
        showModal: true,
        modalType: tableDataType,
        modalTitle: "Logs",
        modalData: ""
      });
      crudActions.post(buttonSettings.endpoint, data, {
        Client: savedGeneratedReport.clientIds
      }).then(
        (response) => {
          this.setState({
            isModalLoading: false,
            modalData: JSON.parse(JSON.stringify(response))
          });
        }
      ).catch(
        (err) => {
          if (err && err.message) {
            this.setState({
              showError: true,
              showModal: false,
              modalTitle: "",
              errorMessage: err.message,
              alertType: "error"
            });
          }
        }
      );
    } else {
      crudActions.post(buttonSettings.endpoint, data, {
        Client: savedGeneratedReport.clientIds
      }).then(
        (response) => {
          const statusMessage = response.message;
          const endpoint = buttonSettings.endpoint; 

          if (endpoint === "/v1/report-buttons/deposits/check-status" || savedGeneratedReport.reportType === "DEPOSITS_PAYOUTS"){
            const status = response.messageType;
            if (status === "SUCCESS") {
              this.setState({
                showSuccess: true,
                successMessage: response.message
              });
            } else if (status === "ERROR") {
              this.setState({
                showError: true,
                errorMessage: response.message
              });
            } else if (status === "NEUTRAL") {
              this.setState({
                showInfo: true,
                infoMessage: response.message
              });
            }
          } else {
            this.setState({ 
              showSuccess: true, 
              successMessage:  endpoint === "/v1/report-buttons/payouts/check-status" ? statusMessage : "Operation successful." });
          }
        }
      ).catch(
        (err) => {
          if (err && err.message) {
            this.setState({
              showError: true,
              errorMessage: err.message
            });
          }
        }
      );
    } 
  };
    
  filterHeaders = (headers) => {
    const { access } = this.state;

    return headers.filter(elem => {
      if (!elem.permission) {
        return true;
      }
     
      const foundPermission = access.find(accessElem => accessElem.permission === elem.permission);
      if (!foundPermission) {
        return true;
      }

      return foundPermission.state;
    });
  };

  filterData = (data) => {
    const { reportsGenerated, access } = this.state;
    return data.filter((elem, index) => {

      const header = reportsGenerated.headers[index];

      if (!header.permission) {
        return true;
      }

      const foundPermission = access.find(accessElem => accessElem.permission === header.permission);
      if (!foundPermission ) {
        return true;
      }
      
      return foundPermission.state;
    });

  };

  sendBulkPostback = () => {
    const { reportsGenerated, reportForm, savedGeneratedReport } = this.state;
    const headers = reportsGenerated.headers;
    const data = reportsGenerated.data;
    const transactionIds = [];
    let index = null;
    if(data && headers){
      if(reportForm.reportType === "TRANSACTIONS"||reportForm.reportType === "RISK_AND_SECURITY") {
        index = headers.map(e => e.headerName).indexOf("Trans. ID");
      }
      if(reportForm.reportType === "PAYOUT") {
        index = headers.map(e => e.headerName).indexOf("Payout ID");
      }
      data.forEach(d => {
        transactionIds.push(d[index]);
      });
    }
    const objectTransactionsIds = {};
    objectTransactionsIds.ids = transactionIds;
    if(reportForm.reportType === "TRANSACTIONS"){
      (crudActions.post('v1/report-buttons/deposit/bulk',objectTransactionsIds,{
        Client: savedGeneratedReport.clientIds
      }
      )).then(
        (response) => {
          this.setState({
            ids: transactionIds,
            showSuccess: true,
            successMessage: "Operation successful."
          });}
      ).catch(
        (err) => {
          if (err && err.message) {
            this.setState({
              showError: true,
              errorMessage: err.message
            });
          }
        }
      );
    }

    if(reportForm.reportType === "PAYOUT"){
      (crudActions.post('/v1/report-buttons/payout/bulk', objectTransactionsIds, {
        Client: savedGeneratedReport.clientIds
      })).then(
        (response) => {
          this.setState({
            ids: transactionIds,
            showSuccess: true,
            successMessage: "Operation successful."
          });}
      ).catch(
        (err) => {
          if (err && err.message) {
            this.setState({
              showError: true,
              errorMessage: err.message
            });
          }
        }
      );
    }
  };

  handleChangePage = (event) => {
    const { currentPage } = this.state;
    let pageNum = currentPage;

    if (event.target.getAttribute('data-testid') === "NavigateBeforeIcon" 
    || event.target.getAttribute('aria-label') === "Go to previous page") {
      pageNum--;
    } else if (event.target.getAttribute('data-testid') === "NavigateNextIcon"
    || event.target.getAttribute('aria-label') === "Go to next page") {
      pageNum++;
    } else
    if (event.target.textContent) {
      pageNum = event.target.textContent;
    }
   
    this.setState({currentPage: pageNum}, () => {
      this.onGenerateReport();
    }); 
  };

  render() {
    const controls = {...this.state.controls};
    const {
      clickButton,
      cnt,
      currentPage,
      dateFieldDisabled,
      enableButton,
      generatedReportType,
      modalData,
      modalType,
      isLoading,
      isLoadingLookups,
      isModalLoading,
      isSortSettingsOpen,
      isExportSettingsOpen,
      reportForm,
      reportsGenerated,
      reportType,
      rowsAmount,
      showModal,
      sortByDate,
      timeStamp,
      timeStampList,
      timeZones
    } = this.state;
    
    const rows = this.getFields();

    const totalPageCount = Math.ceil(cnt / rowsAmount);
    
    if (!isSortSettingsOpen) {
      this.sortNode = null;
    }

    if (!isExportSettingsOpen) {
      this.exportNode = null;
    }

    if (!reportForm.originalData) {
      return <Row flexGrow={ 1 } className='module generateReports' vertical='start'>
        <span/>
      </Row>;
    }

    return (
      <Row flexGrow={ 1 } className="t365 module generateReports" vertical='start'>
        <Column flexGrow={ 1 }>
          <ComponentHeader
            title="Generate Report"
            lastUpdate={ this.state.lastUpdate }
            img={ ReportIcon } />
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    GENERATE REPORT
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <div className="panel-content" style={ {overflow: 'unset'} }>
                    {isLoadingLookups ? (
                      <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                        <Spinner smallContainer={ true } />
                      </div>
                    ) : (
                      <>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                          <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                            <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                              <label> Report Type </label>
                              <Select
                                id="reportType"
                                name="reportType"
                                value={  reportForm.reportType }
                                required={ true }
                                clearable={ false }
                                onChange={ (value) => this.onValueChange(value, 'reportType') }
                                options={ reportType }
                              />
                            </Column>
                            <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                              <label> Merchant </label>
                              <input
                                id="client"
                                name="client"
                                className="form-control"
                                value={ this.state.clientName || '' }
                                disabled
                              />
                            </Column>
                            {(reportForm.reportType ==="TRANSACTIONS" || reportForm.reportType === "PAYOUT" || reportForm.reportType === "PERFORMANCE" ||
                              reportForm.reportType === "DEPOSITS_PAYOUTS") ? (
                                <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                                  <label> Time Stamp </label>
                                  <Select
                                    id="timeStampList"
                                    name="timeStampList"
                                    value={  reportForm.timeStampList }
                                    required={ true }
                                    clearable={ false }
                                    onChange={ (value) => this.onValueChange(value, 'timeStampList') }
                                    options={ timeStampList }
                                  />
                                </Column>
                              ) : reportForm.reportType === "RUNNING_BALANCE" ? (
                                <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                                  <label> Time Zone </label>
                                  <Select
                                    id="timeZone"
                                    name="timeZone"
                                    className={ reportForm.errors.has('timeZone') ? 'error-field' : "" }
                                    value={ reportForm.timeZone }
                                    required={ true }
                                    clearable={ false }
                                    enableSort={ false }
                                    onChange={ (value) => this.onValueChange(value, 'timeZone') }
                                    options={ timeZones }
                                  />
                                </Column>
                              ) : (
                                <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                                  <span/>
                                </Column>
                              )}
                            {(reportForm.reportType === "TRANSACTIONS" || reportForm.reportType === "PERFORMANCE" || reportForm.reportType === "DEPOSITS_PAYOUTS" ||
                              reportForm.reportType === "PAYOUT") ? (
                                <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                                  <label> Time Zone </label>
                                  <Select
                                    id="timeZone"
                                    name="timeZone"
                                    className={ reportForm.errors.has('timeZone') ? 'error-field' : "" }
                                    value={ reportForm.timeZone }
                                    required={ true }
                                    clearable={ false }
                                    enableSort={ false }
                                    onChange={ (value) => this.onValueChange(value, 'timeZone') }
                                    options={ timeZones }
                                  />
                                </Column>
                              ) : (
                                <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                                  <span/>
                                </Column>
                              )}
                          </Row>
                        </Row>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Time Period </label>
                            <Select
                              id="timePeriod"
                              name="timePeriod"
                              value={ controls.timePeriod.value || '' }
                              required={ true }
                              clearable={ false }
                              onChange={ (value) => this.onSelectPeriodChange(value, 'timePeriod') }
                              options={ this.state.dropdowns.periods }
                            />
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label>From Date</label>
                            <img src={ Calendar } className="calendar-svg" alt=""/>
                            <DatePicker
                              selected={ convertUTCToLocalDate(timeStamp.fromDateTimeStamp) || '' }
                              className="form-control"
                              timeInputLabel="Time:"
                              dateFormat={ `dd.MM.yyyy${(
                                reportForm.reportType !== "SETTLEMENTS" &&
                                reportForm.reportType !== "GROSS_PROFIT") ? ' HH:mm' : ''}` }
                              timeFormat="HH:mm"
                              showTimeSelect={
                                reportForm.reportType !== "SETTLEMENTS" &&
                                reportForm.reportType !== "GROSS_PROFIT"
                              }
                              timeIntervals={ 15 }
                              filterDate={ (event) => this.isDateValid(event, 'fromDateTimeStamp') }
                              disabled={ dateFieldDisabled }
                              onChange={ (date) => this.handleDateChange(convertLocalToUTCDate(date), 'fromDateTimeStamp', 'from') }
                            />
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label>To Date</label>
                            <img src={ Calendar } className="calendar-svg" alt=""/>
                            <DatePicker
                              selected={ convertUTCToLocalDate(timeStamp.toDateTimeStamp) || '' }
                              className="form-control"
                              timeInputLabel="Time:"
                              dateFormat={ `dd.MM.yyyy${(
                                reportForm.reportType !== "SETTLEMENTS" &&
                                reportForm.reportType !== "GROSS_PROFIT") ? ' HH:mm' : ''}` }
                              timeFormat="HH:mm"
                              showTimeSelect={
                                reportForm.reportType !== "SETTLEMENTS" &&
                                reportForm.reportType !== "GROSS_PROFIT"
                              }
                              timeIntervals={ 15 }
                              filterDate={ (event) => this.isDateValid(event, 'toDateTimeStamp') }
                              disabled={ dateFieldDisabled }
                              onChange={ (date) => this.handleDateChange(convertLocalToUTCDate(date), 'toDateTimeStamp', 'to') }
                            />
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                            <span/>
                          </Column>
                        </Row>
                        {rows.map((row, index) => {
                          return (
                            <Row key={ index } flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                              {[2, 4].map(count => {
                                return (
                                  <Row key={ index + " " + count } flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                                    {row.slice(count - 2, count).map((field, fieldIndex) => {
                                      return (
                                        <Column
                                          key={ field.id + " " + index + " " + fieldIndex }
                                          flexGrow={ field.flexGrow !== undefined ? parseFloat(field.flexGrow) : 1 }
                                          vertical='end'
                                          alignSelf='start'
                                          className="input-column"
                                        >
                                          {field.type !== "checkbox" && !field.notShowOnCondition && (
                                            <label>
                                              { field.label }
                                            </label>
                                          )}

                                          {field.type === "select" && !field.notShowOnCondition ?
                                            <Select
                                              className={ reportForm.errors.has(field.id) ? 'error-field' : "" }
                                              value={ field.id === "midIds" || field.id === "currency" ? reportForm[field.id].toString() : reportForm[field.id] }
                                              disabled={ field.disabled }
                                              required={ true }
                                              clearable={ false }
                                              options={ field.options }
                                              onChange={ (e) => this.onValueChange(e, field.id) }/>
                                            : field.type === "multiselect" && !field.notShowOnCondition ?
                                              <Multiselect
                                                isError={ reportForm.errors.has(field.id) }
                                                selectedItems={ field.selectedItems }
                                                disabled={ field.disabled }
                                                items={ field.items }
                                                type={ field.id }
                                                withId={ field.withId }
                                                onChange={ this.onValueChange }/>
                                              : field.type === "input" && !field.notShowOnCondition ?
                                                <input
                                                  className={ "form-control " + (reportForm.errors.has(field.id) ? 'error-field' : "") }
                                                  value={ reportForm[field.id] }
                                                  disabled={ field.disabled }
                                                  onChange={ (e) => this.onValueChange(e, field.id) }/>
                                                : field.type === "textarea" && !field.notShowOnCondition ?
                                                  <TextareaAutosize 
                                                    cacheMeasurements
                                                    value={ reportForm[field.id] }
                                                    onChange={ (e) => this.onValueChange(e, field.id) }
                                                    className={ "form-control " + (reportForm.errors.has(field.id) ? 'error-field' : "") }
                                                    disabled={ field.disabled }/>
                                                  :field.type === "bulkButton" && enableButton === true?
                                                    <Button
                                                      type="submit"
                                                      className="btn defaultBtn"
                                                      style={ { outline: isLoading ? "5px auto -webkit-focus-ring-color" : "" } }
                                                      onClick={ this.sendBulkPostback }>
                                                      SEND BULK POSTBACKS
                                                    </Button> 
                                                    : field.type === "checkbox" && !field.notShowOnCondition ?
                                                      <Row className="styled-input--square" wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                                                        <div className="styled-input-single">
                                                          <input type="checkbox" id={ field.id } checked={ reportForm[field.id] }
                                                            onChange={ () => this.onValueChange(reportForm[field.id], [field.id]) }/>
                                                          <label style={ { fontWeight: "normal" } } htmlFor={ field.id }> { field.label } </label>
                                                        </div>
                                                      </Row>
                                                      : field.type === "button" && !field.notShowOnCondition ?
                                                        <Button
                                                          type="submit"
                                                          className="btn defaultBtn"
                                                          style={ { outline: isLoading ? "5px auto -webkit-focus-ring-color" : "" } }
                                                          onClick={ this.onGenerateReport }
                                                        >
                                                          GENERATE
                                                        </Button>
                                                        : <span/>
                                          }
                                        </Column>
                                      );
                                    })}
                                  </Row>
                                );
                              })}
                            </Row>
                          );
                        })}
                      </>
                    )}
                  </div>
                </Panel.Body>
              </Panel>
              { isLoading ? (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title> REPORT GENERATED </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  </Panel.Body>
                </Panel>
              ) : reportsGenerated.headers ? (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>
                      REPORT GENERATED
                    </Panel.Title>
                    <div className="settings-panel">
                      { generatedReportType !== "TRANSACTIONS" && <span className="setting" onClick={ () => this.onSortByOpen() }>
                        <svg className="setting-icon" viewBox="0 0 1792 1792">
                          <path d="M1408 1088q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45zm0-384q0 26-19 45t-45 19h-896q-26 0-45-19t-19-45 19-45l448-448q19-19 45-19t45 19l448 448q19 19 19 45z"/>
                        </svg>
                        <span className="text"> { sortByDate.find(elem => elem.value === reportForm.sortByDate).label } </span>
                      </span> }
                      <span className="setting" style={ { float: "right", paddingRight: "10px" } } onClick={ () => this.onExportOpen() }>
                        <svg className="setting-icon" viewBox="0 0 32 32">
                          <g>
                            <path clipRule="evenodd" d="M8.624,21.336h2.015c1.402-7.953,8.329-14,16.684-14   c0.35,0,0.683,0.003,1.019,0.006l-3.664,3.663c-0.391,0.391-0.391,1.023,0,1.414c0.195,0.195,0.451,0.293,0.707,0.293   c0.256,0,0.512-0.098,0.707-0.293L32,6.356l-5.907-6.063c-0.391-0.391-1.023-0.391-1.414,0c-0.391,0.391-0.391,1.023,0,1.414   l3.631,3.631c-0.318-0.001-0.62-0.003-0.945-0.003C17.895,5.336,10.066,12.271,8.624,21.336z" fillRule="evenodd"/>
                            <path clipRule="evenodd" d="M29,15c-0.552,0-1,0.448-1,1v14H2V10h9c0.552,0,1-0.448,1-1   c0-0.552-0.448-1-1-1h-0.03H2c-1.104,0-2,0.896-2,2v20c0,1.104,0.896,2,2,2h26c1.104,0,2-0.896,2-2V16C30,15.448,29.552,15,29,15z" fillRule="evenodd"/>
                          </g>
                        </svg>
                        <span className="text"> Export </span>
                      </span>
                    </div>
                    { isSortSettingsOpen && (
                      <div ref={ node => this.sortNode = node } className="sort-settings">
                        {
                          sortByDate.map(elem => {
                            return <p key={ elem.value } className="sort-setting-item" onClick={ () => this.onValueChange(elem, 'sortByDate') }> { elem.label } </p>;
                          })
                        }
                      </div>
                    )}
                    { isExportSettingsOpen && (
                      <div ref={ node => this.exportNode = node } className="export-settings">
                        <p className="export-setting-item" onClick={ () => this.onExportClick() }> Export to Excel </p>
                      </div>
                    )}
                  </Panel.Heading>
                  <Panel.Body>
                    <div className="panel-content report-content">
                      <Table className="table table-striped">
                        <thead>
                          <tr style={ {backgroundColor: '#dfdfdf'} }>
                            {this.filterHeaders(reportsGenerated.headers).map((column, i) => {
                              // remove Actions column for Performance report
                              return (column.headerType === "BUTTON" && reportForm.reportType === "PERFORMANCE") ? (
                                <></>
                              ) : (
                                column.headerId !== "ip_country" && (
                                  <th key={ i } className={ column.headerId === "success_rate" || this.isRightAligned(i) ? "text-right" : (column.headerType === "JSON" || column.headerType === "TEXT") ? "text-center" : "" }>
                                    { column.headerName }
                                  </th>
                                )
                              );
                            })
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {reportsGenerated.data.map((element, i) => {
                            return [reportsGenerated.isHistory && (
                              <tr key={ i }>
                                {this.filterData(element).map((tableData, j) => {
                                  return reportsGenerated.headers[j].headerType === "TEXT" ? (
                                    <td
                                      key={ i.toString() + j.toString() }
                                      className={ `text-center ${!tableData ? "no-history" : "history"}` }
                                      onClick={ () => this.onOpenModalClick(tableData, "text") }
                                    >
                                      View
                                    </td>
                                  ) : reportsGenerated.headers[j].headerType === "JSON" ? (
                                    <td
                                      key={ i.toString() + j.toString() }
                                      className="text-center history"
                                      onClick={ () => this.triggerButtonEvent(reportsGenerated.headers, j, element, tableData, "json") }
                                    >
                                      View
                                    </td>
                                  ) : (reportsGenerated.headers[j].headerType === "BUTTON" && reportForm.reportType !== "PERFORMANCE") ? (
                                    <td key={ i.toString() + j.toString() }>
                                      <Button
                                        className={ `btn ${reportsGenerated.headers[j].headerName === 'Reject' ? 'red-button' : 'action-button'}`
                                        && `btn ${this.filterHeaders(reportsGenerated.headers)[j].permission === "CHECK_STATUS" && element[element.length - 1] === "false" ?'grey-button' : 'action-button'}` }
                                        onClick={ () => this.triggerButtonEvent(reportsGenerated.headers, j, element) }>
                                        {this.filterHeaders(reportsGenerated.headers)[j].headerName}
                                      </Button>
                                    </td>
                                  ) : (
                                    reportsGenerated.headers[j].headerType === "BUTTON" && reportForm.reportType === "PERFORMANCE" ? (
                                      <></>
                                    ) : (
                                      reportsGenerated.headers[j].headerId !== "ip_country" && (
                                        <td
                                          key={ i.toString() + j.toString() }
                                          className={ reportsGenerated.headers[j].headerId === "success_rate" || this.isRightAligned(j) ? "text-right " : "" }
                                          style={ { minWidth: reportsGenerated.headers[j].headerId === "ip" ? "170px" : "100px" } }
                                        >
                                          { reportsGenerated.headers[j].headerId === "ip" && reportsGenerated.data[i][this.getReportColumnIndex('ip_country')] && (
                                            <img
                                              alt={ reportsGenerated.data[i][this.getReportColumnIndex('ip_country')] }
                                              style={ { width: "32px", marginRight: "3px" } }
                                              src={ `http://purecatamphetamine.github.io/country-flag-icons/3x2/${reportsGenerated.data[i][this.getReportColumnIndex('ip_country')]}.svg` }/>
                                          )}
                                          { tableData }
                                        </td>
                                      )
                                    )
                                  );
                                })
                                }
                              </tr>
                            ), reportsGenerated.isListDetailed && (
                              <tr key={ i } className={ element.mainRow ? "main-row" : "" } style={ { backgroundColor: !element.mainRow ? '#FFFFFF' : '' } }>
                                { element.content.map((tableData, j) => {
                                  return <td key={ i.toString() + j.toString() } className={ reportsGenerated.headers[j].headerId === "success_rate" || this.isRightAligned(j) ? "text-right" : "" }>
                                    { tableData }
                                  </td>;
                                })}
                              </tr>
                            ), reportsGenerated.isList && (
                              <tr key={ i } style={ {backgroundColor: '#FFFFFF'} }>
                                {element.map((tableData, j) => {
                                  return (reportsGenerated.headers[j].headerType === "TEXT" && tableData) ? (
                                    <td
                                      key={ i.toString() + j.toString() }
                                      className="history"
                                      onClick={ () => this.onOpenModalClick(tableData, "text") }
                                    >
                                      View
                                    </td>
                                  ) : (
                                    <td key={ i.toString() + j.toString() } className={ reportsGenerated.headers[j].headerId === "success_rate" || this.isRightAligned(j) ? "text-right" : "" }>
                                      { tableData }
                                    </td>
                                  );
                                })
                                }
                              </tr>
                            )];
                          })}
                          {reportsGenerated.total && (
                            <tr className="main-row">
                              {reportsGenerated.total.map((elem, i) => {
                                return (
                                  <td key={ i }>
                                    { elem }
                                  </td>
                                );
                              })}
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Panel.Body>
                </Panel>
              ) : (
                <span/>
              )}
              {clickButton && totalPageCount > 0 && (
                <Panel style={ {marginTop : "-23px", borderColor : "white", maxHeight: "105px", minHeight: "100px"} }>
                  <Panel.Body>
                    <div className="pagination">
                      <Pagination 
                        count={ totalPageCount } 
                        variant="outlined" 
                        shape="rounded" 
                        page={ Number(currentPage) }
                        onClick= { this.handleChangePage } 
                      />
                    </div>
                  </Panel.Body>
                  
                </Panel>
              )}
            </Column>
          </Row>
        </Column>
        <SweetAlert
          show={ this.state.showSuccess }
          title="Success"
          type="success"
          confirmButtonColor="#25282a"
          text={ this.state.successMessage || "Report successfully generated." }
          onConfirm={ this.onConfirm }
        />
        <SweetAlert
          show={ this.state.showError }
          title="Error"
          type="error"
          confirmButtonColor={ "#DD6B55" }
          text={ this.state.errorMessage }
          onConfirm={ this.onConfirm }
        />
        <Modal
          title={ modalType === "json" ? "Logs" : "Transaction Notes" }
          handleClose={ this.handleCloseModal }
          showModal={ showModal }
          modalType={ modalType }
          data={ modalData }
          isLoading={ isModalLoading }
        />
        <SweetAlert
          show= { this.state.showAlert }
          title="Alert"
          confirmButtonColor= "#25282a"
          text= { this.state.messageAlert }
          onConfirm= { this.onConfirm }
        />
        <SweetAlert
          show={ this.state.showInfo }
          confirmButtonColor="#25282a"
          text={ this.state.infoMessage }
          onConfirm={ this.onConfirm }
        />

      </Row>
    );
  }
}

export default GenerateReport;