import React, { Component } from 'react';

import Table from "../../Table";
import { Panel } from "react-bootstrap";
import '../../../../assets/css/apiData.css';
import 'react-select/dist/react-select.css';
import { toFormattedNumber } from "../../../../utils/utils.js";
import Spinner from '../../../Spinner';
import { DASHBOARD_PURCHASES_EVENT } from '../../../../actions/types';
const store =  require('../../../../reducers/index');

class DashBoardPurchasesCountriesComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      purchases: undefined,
      loading: true,
      socket: false,
      columns: [{
        value: "clicks",
        label: "Clicks",
        isNumber: true
      }, {
        value: "attempts",
        label: "Attempts",
        rightAligned: true,
        isNumber: true
      }, {
        value: "successful",
        label: "Successful",
        rightAligned: true,
        isNumber: true
      }, {
        value: "value",
        label: "Value",
        rightAligned: true,
        isNumber: true
      }, {
        value: "avgValue",
        label: "Avg. Value",
        rightAligned: true,
        isNumber: true
      }]
    };
  }

  subscribeFunction = null;

  componentDidMount(){
    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().dashboardReducer;

      if (state.update === "LOADING") {
        this.setState({
          loading: true
        });
      }

      if (state.update === DASHBOARD_PURCHASES_EVENT && this.props.thisMonth && state.purchases.socket) {
        this.setState({ purchases: state.purchases, loading: false, socket: state.purchases.socket });
        return;
      }

      if (state.update === DASHBOARD_PURCHASES_EVENT && !state.purchases.socket) {
        this.setState({ purchases: state.purchases, loading: false, socket: state.purchases.socket });
      }
    });
  }

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  tableData = () => {
    const { purchases } = this.state;

    if (!purchases) {
      return [];
    }

    return purchases.map(elem => {
      return {
        users: elem.users,
        clicks: elem.clicks,
        attempts: elem.attempts,
        successful: elem.successful,
        value: elem.currency + " " + toFormattedNumber(elem.value),
        avgValue: elem.currency + " " + toFormattedNumber(elem.avgValue),
        successRate: elem.successRate + "%"
      };
    });
  };

  render() {
    const { purchases, loading, columns } = this.state;

    let purchasesWrap = (
      <Panel>
        <Panel.Heading>
          <Panel.Title> DEPOSITS </Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <Spinner smallContainer={ true } />
        </Panel.Body>
      </Panel>
    );

    if (!loading && purchases) {
      purchasesWrap = (
        <Panel style={ { height: '100%' } }>
          <Panel.Heading>
            <Panel.Title> DEPOSITS </Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <div className="panel-content table-content">
              <Table
                columns={ columns }
                headerColor={ "#DFDFDF" }
                data={ this.tableData() }
                isStriped={ false }
              />
            </div>
          </Panel.Body>
        </Panel>
      );
    }
    return purchasesWrap;
  }

}

export default DashBoardPurchasesCountriesComponent;