import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import { Column } from 'simple-flexbox';
import ReactJson from 'react-json-view';

import Spinner from '../Spinner';

class ModalView extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  };

  render() {
    const { showModal, handleClose, title, data, modalType, isLoading } = this.props;

    return (
      <Modal
        show={ showModal }
        onHide={ handleClose }
        backdrop="static"
        keyboard={ false }
      >
        <Modal.Header closeButton>
          <Modal.Title> { title } </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
              <Spinner smallContainer={ true } />
            </div>
          ) : (
            <Column flexGrow={ 1 } style={ { padding: "25px", overflow: "auto" } }>
              { modalType === "json" ? (
                <ReactJson src={ data } name={ null } displayDataTypes={ false } displayObjectSize={ false } enableClipboard={ false }/>
              ) : (
                <p style={ { whiteSpace: "pre-wrap" } }>
                  { data }
                </p>
              )}
            </Column>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button className="json-modal-close" onClick={ handleClose }>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ModalView;