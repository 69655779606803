import React, { Component } from 'react';
import { Panel, Button } from "react-bootstrap";
import Datetime from 'react-datetime';
import DatePicker from 'react-datepicker';

import Calendar from '../../../assets/images/calendar.png';
import Dropdowns from './dropdowns';
import DashBoardRevenueComponent from "./dashBoardPanelComponents/DashBoardRevenueComponent";
import DashBoardPurchasesComponent from "./dashBoardPanelComponents/DashBoardPurchasesComponent";
import DashBoardPayoutComponent from "./dashBoardPanelComponents/DashBoardPayoutComponent";
import DashBoardPurchaseCountriesComponent from "./dashBoardPanelComponents/DashBoardPurchaseCountriesComponent";
import DashBoardCustomerBalancesComponent from "./dashBoardPanelComponents/DashBoardCustomerBalancesComponent";
import LastUpdateComponent from "./dashBoardPanelComponents/LastUpdateComponent";
import DashBoardPayoutCountriesComponent from "./dashBoardPanelComponents/DashBoardPayoutCountriesComponent";
import DashBoardDepositsProcessingFeeComponent from "./dashBoardPanelComponents/DashBoardDepositsProcessingFeeComponent";
import DashBoardPayoutsProcessingFeeComponent from "./dashBoardPanelComponents/DashBoardPayoutsProcessingFeeComponent";
import DashBoardDepositsPerMidComponent from "./dashBoardPanelComponents/DashBoardDepositsPerMidComponent";
import DashBoardPayoutsPerMidComponent from "./dashBoardPanelComponents/DashBoardPayoutsPerMidComponent";
import DashBoardDisplayDeals from './dashBoardPanelComponents/DashBoardDisplayDeals';
import Select from "../Select";

import { crudActions } from "../../../services/crudActions";
import { DASHBOARD_SELLS_EVENT, DASHBOARD_PURCHASES_EVENT, DASHBOARD_FTD_EVENT,
  DASHBOARD_REVENUE_EVENT, DASHBOARD_USERS_EVENT,
  DASHBOARD_PURCHASE_COUNTRIES_EVENT, DASHBOARD_CUSTOMER_BALANCES_EVENT,
  DASHBOARD_WITHDRAWALS_EVENT, DASHBOARD_PAYOUT_COUNTRIES_EVENT, DASHBOARD_PAYOUT_EVENT,
  DASHBOARD_PAYOUTS_PROCESSING_FEE, DASHBOARD_DEPOSITS_PROCESSING_FEE, DASHBOARD_DEPOSITS_PER_MIDS,
  DASHBOARD_PAYOUTS_PER_MIDS, DASHBOARD_DISPLAY_DEALS, DASHBOARD_AMOUNTS_PER_MID } from '../../../actions/types';
import { Column, Row } from 'simple-flexbox';
import * as actions from '../../../actions';
import { connect } from 'react-redux';

import "react-datepicker/dist/react-datepicker.css";
import 'react-select/dist/react-select.css';
import '../../../assets/css/apiData.css';

import { backEndBaseUrl } from "../../../config";
import { getFormattedDate, convertUTCToLocalDate, convertLocalToUTCDate } from "../../../utils/utils.js";
const combineReducers =  require('../../../reducers/index');

class APIData extends Component {
  state = {
    controls: {
      timePeriod: {
        value:'today'
      },
      from: this.getPeriod(1).from,
      to: this.getPeriod(1).to
    },
    dropdowns: {
      periods: Dropdowns.periods,
      clients: Dropdowns.clients
    },
    timeStamp: {
      fromDateTimeStamp: this.getPeriod(0).from,
      toDateTimeStamp: this.getPeriod(0).to
    },
    dateFieldDisabled: false,
    client: {},
    platforms: [{
      value: "all",
      label: "All",
      prevent_sorting: true
    }],
    selectedPlatform: "all",
    lastUpdate: Datetime.moment().utc().format('HH:mm - DD. MMM YYYY'),
    subscribed: false,
    periodId: 0  //today
  };

  subscribed = false;
  thisMonth = false;
  timeout = 250;
  reconnect = true;
  connectTimeout = null;
  heartbeatInterval = null;
  heartbeatMessage = "ping";

  componentDidMount() {
    const { platforms } = this.state;
    return crudActions.get(`v1/clients/current`).then(
      clientData => {
        if (clientData) {
          this.setState({
            client: {
              id: clientData.id,
              value: clientData.name,
              label: clientData.name
            },
            platforms: platforms.concat(clientData.platforms || [])
          }, () => {
            this.loadRestData();
            // this.connect();
          });
        }
      }
    ).catch(
      (e) => {
        this.props.showMessage(e.message);
      }
    );
  };

  componentWillUnmount() {
    if (this.state.ws) {
      this.state.ws.close();
    }

    this.reconnect = false;
    clearInterval(this.heartbeatInterval);
    clearTimeout(this.connectTimeout);
  };

  connect = () => {
    const { client } = this.state;
    const user = JSON.parse(localStorage.user);
    const ws = new WebSocket(
      (window.location.protocol === "https:" ? "wss" : "ws") + "://" + backEndBaseUrl.split("/")[2] + "/dashboard-ws", //?token=" + user.accessToken
      //possible solution for putting token in headers
      [user.accessToken]
    );
    const that = this;

    ws.onopen = () => {
      console.log("WebSocket connected"); // eslint-disable-line no-console

      this.setState({
        ws: ws
      });

      ws.send(
        JSON.stringify({
          clientIds: [client.id],
          "subscribed": this.subscribed
        })
      );

      const heartbeatMessage = this.heartbeatMessage;

      if (this.heartbeatInterval === null) {
        this.heartbeatInterval = setInterval(function() {
          ws.send(heartbeatMessage);
        }, 60000);
      }

      that.timeout = 250;
      clearTimeout(this.connectTimeout);
    };

    ws.onclose = e => {
      console.log( // eslint-disable-line no-console
        `Socket is closed. Reconnect will be attempted in ${(that.timeout * 2) / 1000} second.`,
        e
      );

      that.timeout = that.timeout * 2;
      this.connectTimeout = setTimeout(this.check, Math.min(10000, that.timeout));
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
    };

    ws.onmessage = evt => {
      if (evt.data === "pong") {
        return;
      }
      const message = JSON.parse(evt.data);
      this.updateDashboard(message);
    };

    ws.onerror = err => {
      console.error( // eslint-disable-line no-console
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );

      ws.close();
    };
  };

  check = () => {
    const { ws } = this.state;
    if ((!ws || ws.readyState === WebSocket.CLOSED) && this.reconnect) {
      // this.connect();
    }
  };

  updateDashboard = (data) => {
    this.setState({
      lastUpdate: Datetime.moment().utc().format('HH:mm - DD. MMM YYYY')
    });

    switch(data.type) {
    case 'USERS':
      combineReducers.default.dispatch({type: DASHBOARD_USERS_EVENT, socket: true, payload: data.data});
      break;
    case 'REVENUE':
      combineReducers.default.dispatch({type: DASHBOARD_REVENUE_EVENT, socket: true, payload: data.data});
      break;
    case 'SELL_REQUESTS':
      combineReducers.default.dispatch({type: DASHBOARD_SELLS_EVENT, socket: true, payload: data.data});
      break;
    case 'DEPOSITS_PER_MID':
      combineReducers.default.dispatch({type: DASHBOARD_DEPOSITS_PER_MIDS, socket: true, payload: data.data});
      break;
    case 'PAYOUTS_PER_MID':
      combineReducers.default.dispatch({type: DASHBOARD_PAYOUTS_PER_MIDS, socket: true, payload: data.data});
      break;
    case 'PURCHASE_PER_COUNTRY':
      combineReducers.default.dispatch({type: DASHBOARD_PURCHASE_COUNTRIES_EVENT, socket: true, payload: data.data});
      //combineReducers.default.dispatch({type: DASHBOARD_PIE_EVENT, socket: true, payload: data.data.purchaseMethodsPie});
      break;
    case 'WITHDRAWALS_PER_COUNTRY':
      combineReducers.default.dispatch({type: DASHBOARD_WITHDRAWALS_EVENT, socket: true, payload: data.data});
      //combineReducers.default.dispatch({type: DASHBOARD_PIE_EVENT, socket: true, payload: data.data.purchaseMethodsPie});
      break;
    case 'FTD_STATS':
      combineReducers.default.dispatch({type: DASHBOARD_PURCHASES_EVENT, socket: true, payload: data.data.purchases});
      combineReducers.default.dispatch({type: DASHBOARD_FTD_EVENT, socket: true, payload: data.data.ftds});
      break;
    case 'CUSTOMER_BALANCES':
      combineReducers.default.dispatch({type: DASHBOARD_CUSTOMER_BALANCES_EVENT, socket: true, payload: data.data});
      break;
    case 'PAYOUT_PER_COUNTRY':
      combineReducers.default.dispatch({type: DASHBOARD_PAYOUT_COUNTRIES_EVENT, socket: true, payload: data.data});
      break;
    case 'PAYOUT_REQUESTS':
      combineReducers.default.dispatch({type: DASHBOARD_PAYOUT_EVENT, socket: true, payload: data.data});
      break;
    case 'DEPOSIT_PROCESSING_FEES':
      combineReducers.default.dispatch({type: DASHBOARD_DEPOSITS_PROCESSING_FEE, socket: true, payload: data.data});
      break;
    case 'PAYOUT_PROCESSING_FEES':
      combineReducers.default.dispatch({type: DASHBOARD_PAYOUTS_PROCESSING_FEE, socket: true, payload: data.data});
      break;
    case 'DEPOSITS_VOLUME_PER_MID':
      combineReducers.default.dispatch({type: DASHBOARD_AMOUNTS_PER_MID, socket: true, payload: data.data});
      break;
    case 'DISPLAY_DEALS':
      combineReducers.default.dispatch({type: DASHBOARD_DISPLAY_DEALS, socket: true, payload: data.data});
      break;
    default:
      console.log('Wrong type ' + data.type); // eslint-disable-line no-console
    }
  }

  loadRestData = () => {
    const { client, selectedPlatform, timeStamp, periodId } = this.state;
    const period = this.getPeriod(periodId);
    if (periodId !== 8) {
      //we do not override dates for custom date picker
      timeStamp.fromDateTimeStamp = period.from;
      timeStamp.toDateTimeStamp = period.to;
    }

    const fromDateTimeStamp = Datetime.moment(this.state.timeStamp.fromDateTimeStamp).utc().format("DD.MM.YYYY-HH:mm:ss");
    const toDateTimeStamp = Datetime.moment(this.state.timeStamp.toDateTimeStamp).utc().format("DD.MM.YYYY-HH:mm:ss");

    this.setState({
      lastUpdate: Datetime.moment().utc().format('HH:mm - DD. MMM YYYY')
    });

    crudActions.get(`v2/clients/dashboard/revenue?fromDate=${fromDateTimeStamp}&toDate=${toDateTimeStamp}&thisMonth=${this.thisMonth}&clients=${client.id}&platform=${selectedPlatform}`)
      .then(data => {
        data.sort((revenueA, revenueB) => {
          return revenueA.currency > revenueB.currency ? 1 : revenueA.currency < revenueB.currency ? -1 : 0;
        });
        combineReducers.default.dispatch({type: DASHBOARD_REVENUE_EVENT, socket: false, payload: data});
      })
      .catch(e => {
        this.props.showMessage(e.message);
      });

    crudActions.get(`v2/clients/dashboard/country/purchases?fromDate=${fromDateTimeStamp}&toDate=${toDateTimeStamp}&thisMonth=${this.thisMonth}&clients=${client.id}&platform=${selectedPlatform}`)
      .then(data => {
        combineReducers.default.dispatch({type: DASHBOARD_PURCHASE_COUNTRIES_EVENT, socket: false, payload: data});
      //combineReducers.default.dispatch({type: DASHBOARD_PIE_EVENT, sse: false, payload: data.purchaseMethodsPie});
      })
      .catch(e => {
        this.props.showMessage(e.message);
      });

    crudActions.get(`v2/clients/dashboard/payout?fromDate=${fromDateTimeStamp}&toDate=${toDateTimeStamp}&thisMonth=${this.thisMonth}&clients=${client.id}&platform=${selectedPlatform}`)
      .then(data => {
        combineReducers.default.dispatch({type: DASHBOARD_PAYOUT_EVENT, socket: false, payload: data});
      })
      .catch(e => {
        this.props.showMessage(e.message);
      });

    crudActions.get(`v2/clients/dashboard/ftd?fromDate=${fromDateTimeStamp}&toDate=${toDateTimeStamp}&thisMonth=${this.thisMonth}&clients=${client.id}&platform=${selectedPlatform}`)
      .then(data => {
        combineReducers.default.dispatch({type: DASHBOARD_PURCHASES_EVENT, socket: false, payload: data.purchases});
        combineReducers.default.dispatch({type: DASHBOARD_FTD_EVENT, socket: false, payload: data.ftds});
      })
      .catch(e => {
        this.props.showMessage(e.message);
      });

    crudActions.get(`v2/clients/dashboard/customer/balances?fromDate=${fromDateTimeStamp}&toDate=${toDateTimeStamp}&thisMonth=${this.thisMonth}&clients=${client.id}&platform=${selectedPlatform}`)
      .then(data => {
        combineReducers.default.dispatch({type: DASHBOARD_CUSTOMER_BALANCES_EVENT, socket: false, payload: data});
      })
      .catch(e => {
        this.props.showMessage(e.message);
      });

    crudActions.get(`v2/clients/dashboard/country/payout?fromDate=${fromDateTimeStamp}&toDate=${toDateTimeStamp}&thisMonth=${this.thisMonth}&clients=${client.id}&platform=${selectedPlatform}`)
      .then(data => {
        combineReducers.default.dispatch({type: DASHBOARD_PAYOUT_COUNTRIES_EVENT, socket: false, payload: data});
      })
      .catch(e => {
        this.props.showMessage(e.message);
      });

    crudActions.get(`v2/clients/dashboard/deposit-fees?fromDate=${fromDateTimeStamp}&toDate=${toDateTimeStamp}&thisMonth=${this.thisMonth}&clients=${client.id}&platform=${selectedPlatform}`)
      .then(data => {
        combineReducers.default.dispatch({type: DASHBOARD_DEPOSITS_PROCESSING_FEE, socket: false, payload: data});
      })
      .catch(e => {
        this.props.showMessage(e.message);
      });

    crudActions.get(`v2/clients/dashboard/deposits-per-mid?fromDate=${fromDateTimeStamp}&toDate=${toDateTimeStamp}&thisMonth=${this.thisMonth}&clients=${client.id}&platform=${selectedPlatform}`)
      .then(data => {
        combineReducers.default.dispatch({type: DASHBOARD_DEPOSITS_PER_MIDS, socket: false, payload: data});
      })
      .catch(e => {
        this.props.showMessage(e.message);
      });

    crudActions.get(`v2/clients/dashboard/payouts-per-mid?fromDate=${fromDateTimeStamp}&toDate=${toDateTimeStamp}&thisMonth=${this.thisMonth}&clients=${client.id}&platform=${selectedPlatform}`)
      .then(data => {
        combineReducers.default.dispatch({type: DASHBOARD_PAYOUTS_PER_MIDS, socket: false, payload: data});
      })
      .catch(e => {
        this.props.showMessage(e.message);
      });

    crudActions.get(`v2/clients/dashboard/payout-fees?fromDate=${fromDateTimeStamp}&toDate=${toDateTimeStamp}&thisMonth=${this.thisMonth}&clients=${client.id}&platform=${selectedPlatform}`)
      .then(data => {
        combineReducers.default.dispatch({type: DASHBOARD_PAYOUTS_PROCESSING_FEE, socket: false, payload: data});
      })
      .catch(e => {
        this.props.showMessage(e.message);
      });
    crudActions.get(`v2/clients/dashboard/display-deals?fromDate=${fromDateTimeStamp}&toDate=${toDateTimeStamp}&thisMonth=${this.thisMonth}&clients=${client.id}&platform=${selectedPlatform}`)
      .then(data => {
        combineReducers.default.dispatch({type: DASHBOARD_DISPLAY_DEALS, socket: false, payload: data});
      })
      .catch(e => {
        this.props.showMessage(e.message);
      });
    crudActions.get(`v2/clients/dashboard//amounts-per-mid?fromDate=${fromDateTimeStamp}&toDate=${toDateTimeStamp}&thisMonth=${this.thisMonth}&clients=${client.id}&platform=${selectedPlatform}`)
      .then(data => {
        combineReducers.default.dispatch({type: DASHBOARD_AMOUNTS_PER_MID, socket: false, payload: data});
      })
      .catch(e => {
        this.props.showMessage(e.message);
      });

  };

  onChangeFilter = (e) => {
    const { /*client, ws,*/ selectedPlatform } = this.state;
    e.preventDefault();
    this.subscribed = this.thisMonth && selectedPlatform === "all";
    // ws.send(
    //   JSON.stringify({
    //     "subscribed": this.subscribed
    //   })
    // );
    this.loadRestData();
  };

  getPeriod(periodType) {
    const moment = Datetime.moment;

    switch (periodType) {
    case 1: // yesterday
      return {
        from: moment().utc().subtract(1, 'day').hour(0).minute(0).second(0),
        to: moment().utc().subtract(1, 'day').hour(23).minute(59).second(59),
        id: periodType
      };
    case 2: // this month
      return {
        from: moment().utc().year(moment().utc().year()).month(moment().utc().month()).date(1).hour(0).minute(0).second(0),
        to: moment().utc().hour(23).minute(59).second(59),
        id: periodType
      };
    case 3: // last month
      return {
        from: moment().utc().year(moment().utc().year()).month(moment().utc().month() - 1).date(1).hour(0).minute(0).second(0),
        to: moment().utc().year(moment().utc().year()).month(moment().utc().month() - 1)
          .date(moment().utc().month(moment().utc().month() - 1).daysInMonth()).hour(23).minute(59).second(59),
        id: periodType
      };
    case 4: // this year
      return {
        from: moment().utc().year(moment().utc().year()).month(0).date(1).hour(0).minute(0).second(0),
        to: moment().utc().hour(23).minute(59).second(59),
        id: periodType
      };
    case 5: // last year
      return {
        from: moment().utc().year(moment().utc().year() - 1).month(0).date(1).hour(0).minute(0).second(0),
        to: moment().utc().year(moment().utc().year() - 1).month(11).date(31).hour(23).minute(59).second(59),
        id: periodType
      };
    case 6: // last hour
      return {
        from: moment().utc().subtract(1, 'hour'),
        to: moment().utc(),
        id: periodType
      };
    case 7: // last 6 hours
      return {
        from: moment().utc().subtract(6, 'hour'),
        to: moment().utc(),
        id: periodType
      };
    case 0: // today
    default: // custom period
      return {
        from: moment().utc().hour(0).minute(0).second(0),
        to: moment().utc(),
        id: periodType
      };
    }
  };

  onSelectPeriodChange = (value, id) => {
    const timeStamp = {...this.state.timeStamp};
    const controls = {...this.state.controls};
    value = value === null ? {
      id: 0,
      value: 'today',
      label: 'Today'
    } : value;
    const dateFieldDisabled = value.id > 0 && value.id <= 7; // depends on any predefined period (today, yesterday etc.)
    const period = this.getPeriod(value.id);

    controls[id] = value;
    timeStamp.fromDateTimeStamp = period.from;
    timeStamp.toDateTimeStamp = period.to;
    controls.from = getFormattedDate(timeStamp.fromDateTimeStamp._d);
    controls.to = getFormattedDate(timeStamp.toDateTimeStamp._d);

    //if this month
    if (value.id === 2) {
      this.thisMonth = true;
    } else {
      this.thisMonth = false;
    }
    //

    this.setState({
      controls: controls,
      dateFieldDisabled: dateFieldDisabled,
      timeStamp: timeStamp,
      periodId: value.id
    });
  };

  onSelectClientChange = (value) => {
    this.setState({
      selectedClient: value
    });
  };

  handleDateChange = (event, state, fieldState) => {
    const controls = {...this.state.controls};
    const timeStamp = {...this.state.timeStamp};

    timeStamp[state] = event;
    controls[fieldState] = event;

    this.setState({controls: controls, timeStamp: timeStamp, isSubmitting: false});
  };

  isDateValid = (current, state) => {
    const { timeStamp, fromDate = timeStamp.fromDateTimeStamp, toDate = timeStamp.toDateTimeStamp } = this.state;
    const yesterday = Datetime.moment().utc().subtract(1, 'days');

    if (state === 'fromDateTimeStamp') {
      return Datetime.moment(current).isBefore(!fromDate ? yesterday : toDate);
    }

    return Datetime.moment(current).isAfter(!toDate ? yesterday : Datetime.moment(fromDate).subtract(1, 'days'));
  };

  onSelectPlatform = (event) => {
    this.setState({
      selectedPlatform: event.value
    });
  };

  render() {
    const controls = {...this.state.controls};
    const { platforms, selectedPlatform, lastUpdate } = this.state;

    return (
      <Row flexGrow={ 1 } className="t365 module apidata" vertical='start'>
        <Column flexGrow={ 1 }>
          <LastUpdateComponent lastUpdate={ lastUpdate }/>
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' style={ {paddingLeft: 15, paddingRight: 15, paddingTop: 15, width: '100%'} }>
              <Panel>
                <Panel.Heading>
                  <Panel.Title>TIME PERIOD</Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <div className="panel-content" style={ {overflow: 'unset'} }>
                    <form onSubmit={ (e) => this.onRunFilter(e) }>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' className="filter-row">
                        <Column flexGrow={ 1 } className="input-column">
                          <label> Merchant </label>
                          <input id="client"
                            name="client"
                            className="form-control"
                            value={ this.state.client.value || '' }
                            disabled
                          />
                        </Column>
                        <Column flexGrow={ 1 } className="input-column">
                          <label> Platform </label>
                          <Select id="selectedPlatform"
                            name="selectedPlatform"
                            value={ selectedPlatform || '' }
                            required={ true }
                            clearable={ false }
                            onChange={ (value) => this.onSelectPlatform(value) }
                            options={ platforms }
                          />
                        </Column>
                        <Column flexGrow={ 1 } className="input-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } className="input-column">
                          <span/>
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' className="filter-row">
                        <Column flexGrow={ 1 } className="input-column">
                          <label>Time Period</label>
                          <Select id="timePeriod"
                            name="timePeriod"
                            value={ controls.timePeriod.value || '' }
                            required={ true }
                            clearable={ false }
                            onChange={ (value) => this.onSelectPeriodChange(value, 'timePeriod') }
                            options={ this.state.dropdowns.periods }
                          />
                        </Column>
                        <Column flexGrow={ 1 } className="input-column first-datepicker" alignSelf='start'>
                          <label>From Date</label>
                          <img src={ Calendar } className="calendar-svg" alt=""/>
                          <DatePicker
                            selected={ convertUTCToLocalDate(this.state.timeStamp.fromDateTimeStamp) || '' }
                            className="form-control"
                            timeInputLabel="Time:"
                            dateFormat="dd.MM.yyyy HH:mm"
                            timeFormat="HH:mm"
                            showTimeSelect={ true }
                            timeIntervals={ 15 }
                            filterDate={ (event) => this.isDateValid(event, 'fromDateTimeStamp') }
                            disabled={ this.state.dateFieldDisabled }
                            onChange={ (date) => this.handleDateChange(convertLocalToUTCDate(date), 'fromDateTimeStamp', 'from') }
                          />
                        </Column>
                        <Column flexGrow={ 1 } className="input-column" alignSelf='start'>
                          <label>To Date</label>
                          <img src={ Calendar } className="calendar-svg" alt=""/>
                          <DatePicker
                            selected={ convertUTCToLocalDate(this.state.timeStamp.toDateTimeStamp) || '' }
                            className="form-control"
                            timeInputLabel="Time:"
                            dateFormat="dd.MM.yyyy HH:mm"
                            timeFormat="HH:mm"
                            showTimeSelect={ true }
                            timeIntervals={ 15 }
                            filterDate={ (event) => this.isDateValid(event, 'toDateTimeStamp') }
                            disabled={ this.state.dateFieldDisabled }
                            onChange={ (date) => this.handleDateChange(convertLocalToUTCDate(date), 'toDateTimeStamp', 'from') }
                          />
                        </Column>
                        <Column flexGrow={ 1 } className="input-column fullwidth-button">
                          <Button
                            type="submit"
                            className="btn defaultBtn"
                            disabled={ this.state.isSubmitting }
                            onClick={ (e) => this.onChangeFilter(e) }
                          >
                            Filter
                          </Button>
                        </Column>
                      </Row>
                    </form>
                  </div>
                </Panel.Body>
              </Panel>
            </Column>
            <Column className="table-column" flexGrow={ 1 } vertical='start'>
              <DashBoardRevenueComponent thisMonth={ this.thisMonth }/>
            </Column>

            <Row className="dashboard-row" flexGrow={ 1 } horizontal='start' alignSelf="start" style={ {width: '100%'} }>
              <Column className="table-column" flexGrow={ 1 } vertical='start' >
                <DashBoardDepositsPerMidComponent thisMonth={ this.thisMonth }/>
              </Column>
            </Row>

            <Row className="dashboard-row" flexGrow={ 1 } horizontal='start' alignSelf="start" style={ {width: '100%'} }>
              <Column className="table-column" flexGrow={ 1 } vertical='start' >
                <DashBoardPayoutsPerMidComponent thisMonth={ this.thisMonth }/>
              </Column>
            </Row>

            <Row className="dashboard-row" flexGrow={ 1 } horizontal='start' alignSelf="start" style={ {width: '100%'} }>
              <Column className="table-column" flexGrow={ 1 } vertical='start' style={ {paddingLeft: 15, paddingRight: 15, width: '100%'} }>
                <DashBoardCustomerBalancesComponent thisMonth={ this.thisMonth }/>
              </Column>
              <Column className="table-column" flexGrow={ 1 } vertical='start'>
                <DashBoardPurchaseCountriesComponent thisMonth={ this.thisMonth }/>
              </Column>
            </Row>

            <Row className="dashboard-row" flexGrow={ 1 } horizontal='start' alignSelf="start" style={ {width: '100%'} }>
              <Column className="table-column" flexGrow={ 1 } vertical='start'>
                <DashBoardPayoutCountriesComponent thisMonth={ this.thisMonth }/>
              </Column>
              <Column className="table-column" flexGrow={ 1 } vertical='start'>
                <DashBoardPurchasesComponent thisMonth={ this.thisMonth }/>
              </Column>
            </Row>

            <Row className="dashboard-row" flexGrow={ 1 } horizontal='start' alignSelf="start" style={ {width: '100%'} }>
              <Column className="table-column" flexGrow={ 1 } vertical='start'>
                <DashBoardPayoutComponent thisMonth={ this.thisMonth }/>
              </Column>
              <Column className="table-column" flexGrow={ 1 } vertical='start'>
                <DashBoardDepositsProcessingFeeComponent thisMonth={ this.thisMonth }/>
              </Column>
            </Row>
            <Row className="dashboard-row" flexGrow={ 1 } horizontal='start' alignSelf="start" style={ {width: '100%'} }>
              <Column className="table-column" flexGrow={ 1 } vertical='start'>
                <DashBoardPayoutsProcessingFeeComponent thisMonth={ this.thisMonth }/>
              </Column>
              <Column className="table-column" flexGrow={ 1 } vertical='start'>
                <span/>
              </Column>
            </Row>
            <Column className="table-column" flexGrow={ 1 } vertical='start'>
              <DashBoardDisplayDeals thisMonth={ this.thisMonth }/>
            </Column>

          </Row>

        </Column>
      </Row>
    );
  }
}

export default connect(null, actions)(APIData);
