import { requestUrl } from "./backendUrl";

export const crudService = {
  get,
  post,
  put
};

const user = JSON.parse(localStorage.getItem('user'));

function buildUrl(endpoint) {
  return requestUrl.backendUrl + endpoint;
}

function get(endpoint, customHeaders = {}) {
  const url = buildUrl(endpoint);

  const requestOptions = {
    method: 'GET',
    headers: Object.assign(customHeaders, {
      'Content-Type': 'application/json',
      'Authorization': user.tokenType + ' ' + user.accessToken
    })
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function post(endpoint, jsonConfig, customHeaders = {}) {
  const url = buildUrl(endpoint);

  const requestOptions = {
    method: "POST",
    body: JSON.stringify(jsonConfig),
    headers: Object.assign(customHeaders, {
      'Content-Type': 'application/json',
      'Authorization': user.tokenType + ' ' + user.accessToken
    })
  };

  return fetch(url, requestOptions).then(handleResponse);

}

function put(endpoint, jsonConfig, customHeaders = {}) {
  const url = buildUrl(endpoint);

  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(jsonConfig),
    headers: Object.assign(customHeaders, {
      'Content-Type': 'application/json',
      'Authorization': user.tokenType + ' ' + user.accessToken
    })
  };

  return fetch(url, requestOptions).then(handleResponse);

}

function handleResponse(response) {
  if (!response.ok) {
    if (response.status === 401) {
      localStorage.removeItem('user');
      window.location.href = "/login";
    }
    return Promise.reject(response);
  }

  const responseCloned = response.clone();
  return response.json().catch(
    () => {
      return responseCloned;
    }
  );
}