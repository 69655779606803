import React, {Component} from 'react';

import Table from "../../Table";
import { Panel } from "react-bootstrap";
import '../../../../assets/css/apiData.css';
import 'react-select/dist/react-select.css';
import { toFormattedNumber } from "../../../../utils/utils.js";
import Spinner from '../../../Spinner';
import { DASHBOARD_PAYOUT_COUNTRIES_EVENT } from '../../../../actions/types';
const store =  require('../../../../reducers/index');

class DashBoardPayoutCountriesComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      payout: undefined,
      loading: true,
      socket: false,
      columns: [{
        value: "country",
        label: "Country"
      }, {
        value: "settlement",
        label: "Settlement",
        isNumber: true
      }, {
        value: "amount",
        label: "Total",
        rightAligned: true,
        isNumber: true
      }]
    };
  }

  subscribeFunction = null;

  componentDidMount(){
    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().dashboardReducer;

      if (state.update === "LOADING") {
        this.setState({
          loading: true
        });
      }

      if (state.update === DASHBOARD_PAYOUT_COUNTRIES_EVENT && this.props.thisMonth && state.payoutcountry.socket) {
        this.setState({ payout: state.payoutcountry, loading: false, socket: state.payoutcountry.socket });
        return;
      }

      if (state.update === DASHBOARD_PAYOUT_COUNTRIES_EVENT && !state.payoutcountry.socket) {
        this.setState({ payout: state.payoutcountry, loading: false, socket: state.payoutcountry.socket });
      }
    });
  }

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  tableData = () => {
    const { payout } = this.state;

    if (!payout) {
      return [];
    }

    return payout.map(elem => {
      return {
        country: elem.country,
        settlement: elem.settlement,
        amount: elem.currency + " " + toFormattedNumber(elem.amount),
        fxFee: elem.currency + " " + toFormattedNumber(elem.fxFee),
        sellFee: elem.currency + " " + toFormattedNumber(elem.sellFee)
      };
    });
  };

  render() {
    const { payout, loading, columns } = this.state;

    let payoutWrap = (
      <Panel>
        <Panel.Heading>
          <Panel.Title> PAYOUT PER COUNTRY </Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <Spinner smallContainer={ true } />
        </Panel.Body>
      </Panel>
    );

    if (!loading && payout) {
      payoutWrap = (
        <Panel style={ { height: '100%' } }>
          <Panel.Heading>
            <Panel.Title> PAYOUT PER COUNTRY </Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <div className="panel-content table-content">
              <Table
                columns={ columns }
                headerColor={ "#DFDFDF" }
                data={ this.tableData() }
                isStriped={ false }
              />
            </div>
          </Panel.Body>
        </Panel>
      );
    }
    return payoutWrap;
  }

}

export default DashBoardPayoutCountriesComponent;