import Datetime from 'react-datetime';

export function getFormattedDate(date) {
  const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
  const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);

  return `${day}.${month}.${date.getFullYear()}`;
};

export function toFormattedNumber(value) {
  return Number(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export function convertUTCToLocalDate(date) {
  if (!date) {
    return date;
  }

  date = Datetime.moment(date);

  date = new Date(
    date.year(), date.month(), date.date(),
    date.hour(), date.minute(), date.second()
  );

  return date;
};

export function convertLocalToUTCDate(date) {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(
    date.getFullYear(), date.getMonth(), date.getDate(),
    date.getHours(), date.getMinutes()
  );
  return date;
};