import React, {Component} from 'react';

import { Panel } from "react-bootstrap";
import Table from "../../Table";
import '../../../../assets/css/apiData.css';
import 'react-select/dist/react-select.css';
import Spinner from '../../../Spinner';
import { DASHBOARD_DISPLAY_DEALS } from '../../../../actions/types';
const store =  require('../../../../reducers/index');

class DashBoardDisplayDeals extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deals:{},
      loading: true,
      socket: false,
      depositColumns: [{
        value: "channelId",
        label: "PID",
        leftAligned: true,
        isNumber: true,
        width: "10%"
      }, {
        value: "paymentMethod",
        label: "Payment Method",
        rightAligned: true,
        isNumber: false,
        width: "10%"
      }, {
        value: "currency",
        label: "Currency",
        rightAligned: true,
        isNumber: true,
        width: "10%"
      }, {
        value: "processingFee",
        label: "Processing Fee",
        rightAligned: true,
        isNumber: true,
        width: "10%"
      }, {
        value: "txFee",
        label: "TX Fee",
        rightAligned: true,
        isNumber: true,
        width: "7%"
      },{
        value: "chargebackFee",
        label: "CB Fee",
        rightAligned: true,
        isNumber: true,
        width: "7%"
      }, {
        value: "rollingReverse",
        label: "RR %",
        rightAligned: true,
        isNumber: true,
        width: "7%"
      }, {
        value: "rollingReverseDays",
        label: "RR Days",
        rightAligned: true,
        isNumber: true,
        width: "7%"
      }, {
        value: "tx",
        label: "T+X",
        rightAligned: true,
        isNumber: true,
        width: "7%"
      }, {
        value: "amountRange",
        label: "Min/Max Amount",
        rightAligned: true,
        isNumber: true,
        width: "10%"
      }],

      payoutColumns: [{
        value: "channelId",
        label: "PID",
        leftAligned: true,
        isNumber: true,
        width: "10%"
      }, {
        value: "paymentMethod",
        label: "Payout Method",
        rightAligned: true,
        isNumber: false,
        width: "10%"
      }, {
        value: "currency",
        label: "Currency",
        rightAligned: true,
        isNumber: true,
        width: "10%"
      }, {
        value: "processingFee",
        label: "Payout Fee",
        rightAligned: true,
        isNumber: true,
        width: "10%"
      }, {
        value: "txFee",
        label: "TX Fee",
        rightAligned: true,
        isNumber: true,
        width: "7%"
      }, {
        value: "test",
        label: "",
        rightAligned: true,
        isNumber: true,
        width: "7%"
      }, {
        value: "test1",
        label: "",
        rightAligned: true,
        isNumber: true,
        width: "7%"
      }, {
        value: "test2",
        label: "",
        rightAligned: true,
        isNumber: true,
        width: "7%"
      }, {
        value: "test3",
        label: "",
        rightAligned: true,
        isNumber: true,
        width: "7%"
      }, {
        value: "test4",
        label: "",
        rightAligned: true,
        isNumber: true,
        width: "7%"
      }, {
        value: "amountRange",
        label: "Min/Max Amount",
        rightAligned: true,
        isNumber: true,
        width: "10%"
      }]
     
    };
  }

  subscribeFunction = null;

  componentDidMount(){
    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().dashboardReducer;

      if (state.update === "LOADING") {
        this.setState({
          loading: true
        });
      }

      if (state.update === DASHBOARD_DISPLAY_DEALS && this.props.thisMonth ) {
        this.setState({ deals: state.deals, loading: false,});
        
        return;
      }

      if (state.update === DASHBOARD_DISPLAY_DEALS  ) {
        this.setState({  deals: state.deals, loading: false});
 
      }
      
    });
  };

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  depositsTableData = () => {
    const { deals } = this.state;

    if (!deals || !deals.depositDeals) {
      return [];
    }

    const copyDepositDeals= JSON.parse(JSON.stringify(deals.depositDeals));

    copyDepositDeals.forEach(element => {
      delete element.paymentMethod;
    });
    const unique = [];

    copyDepositDeals.filter(function(item){
      const i = unique.findIndex(x => (x.channelId===item.channelId && x.currency === item.currency && x.processingFee === item.processingFee &&
        x.txFee === item.txFee && x.chargebackFee === item.chargebackFee && x.maxAmount === item.maxAmount && x.rollingReverse === item.rollingReverse &&
        x.rollingReverseDays === item.rollingReverseDays && x.tx === item.tx && x.minAmount === item.minAmount ));
      if(i <= -1){
        unique.push(item);
      }
      return null;
    });
    const map1 = new Map();
    deals.depositDeals.sort((a, b) => a.channelId - b.channelId).forEach(item=>{
      unique.forEach(x=>{
        if(x.channelId===item.channelId && x.currency === item.currency && x.processingFee === item.processingFee && x.txFee === item.txFee &&
          x.chargebackFee === item.chargebackFee && x.maxAmount === item.maxAmount && x.rollingReverse === item.rollingReverse &&
          x.rollingReverseDays === item.rollingReverseDays && x.tx === item.tx && x.minAmount === item.minAmount){
          if(map1.has(x) ){
            const unitePaymentMethod= map1.get(x).concat('/', item.paymentMethod);
            map1.set(x,unitePaymentMethod);
          } else {
            map1.set(x,item.paymentMethod);
          }}
        
      });
    });
  
    return Array.from(map1).map(elem => {
      return {
        channelId: elem[0].channelId,
        paymentMethod: elem[1],
        currency: elem[0].currency,
        processingFee: elem[0].processingFee,
        txFee: elem[0].txFee,
        chargebackFee: elem[0].chargebackFee,
        rollingReverse: elem[0].rollingReverse,
        rollingReverseDays: elem[0].rollingReverseDays,
        tx: elem[0].tx,
        amountRange: elem[0].minAmount + '/' + elem[0].maxAmount
      };
    });
  };

  payoutsTableData = () => {
    const { deals } = this.state;

    if (!deals || !deals.payoutDeals) {
      return [];
    }
    const copyPayoutDeals= JSON.parse(JSON.stringify(deals.payoutDeals));

    copyPayoutDeals.forEach(element => {
      delete element.paymentMethod;
    });
    const unique = [];

    copyPayoutDeals.filter(function(item){
      const i = unique.findIndex(x => (x.channelId===item.channelId && x.currency === item.currency && x.processingFee === item.processingFee &&
      x.txFee === item.txFee && x.tx === item.tx && x.minAmount === item.minAmount  && x.maxAmount === item.maxAmount ));
      if(i <= -1){
        unique.push(item);
      }
      return null;
    });
    const map1 = new Map();
    deals.payoutDeals.sort((a, b) => a.channelId - b.channelId).forEach(item=>{
      unique.forEach(x=>{
        if(x.channelId===item.channelId && x.currency === item.currency && x.processingFee === item.processingFee &&
          x.txFee === item.txFee && x.tx === item.tx && x.minAmount === item.minAmount  && x.maxAmount === item.maxAmount ){
          if(map1.has(x)){
            const unitePaymentMethod= map1.get(x).concat('/', item.paymentMethod);
            map1.set(x,unitePaymentMethod);
          } else {
            map1.set(x,item.paymentMethod);
          }}
        
      });
    });
    return Array.from(map1).map(elem => {
      return {
        channelId: elem[0].channelId,
        paymentMethod: elem[1],
        currency: elem[0].currency,
        processingFee: elem[0].processingFee,
        txFee: elem[0].txFee,
        tx: elem[0].tx,
        amountRange: elem[0].minAmount + '/' + elem[0].maxAmount
      };
    });
  };
    
  render() {
    const { deals, loading, depositColumns, payoutColumns} = this.state;
    
    let sellsWrap = (
      <Panel>
        <Panel.Heading>
          <Panel.Title> PRICING </Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <Spinner smallContainer={ true } />
        </Panel.Body>
      </Panel>
    );
      
    if (!loading && deals) {
      sellsWrap = (
        <Panel style={ { height: '100%' } }>
          <Panel.Heading>
            <Panel.Title> PRICING </Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <div className="panel-content table-content payout-requests">
              <Table
                columns={ depositColumns }
                headerColor={ "#DFDFDF" }
                data={ this.depositsTableData() }
                isStriped={ false }
              />
              <Table
                columns={ payoutColumns }
                headerColor={ "#DFDFDF" }
                data={ this.payoutsTableData() }
                isStriped={ false }
              />
            </div>
          </Panel.Body>
        </Panel>
      );
    }
    return sellsWrap;
  }

}

export default DashBoardDisplayDeals;