import React, { Component } from 'react';
import { Row, Column } from 'simple-flexbox';
import { Panel, Button } from "react-bootstrap";
import SweetAlert from 'sweetalert2-react';
import { withTranslation } from 'react-i18next';

import ComponentHeader from "../../componentHeader";
import Form from "../../../core/Form";
import Spinner from '../../Spinner';

import '../../../assets/css/myAccount.css';
import ReportIcon from '../../../assets/images/report.png';

import { crudActions } from "../../../services/crudActions";

class MyAccount extends Component {
  state = {
    accountForm: new Form({
      name: "",
      phone: "",
      skype: "",
      currentPassword: null,
      newPassword: null
    }, [{
      name: "name",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "phone",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "skype",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "currentPassword",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "newPassword",
      type: "isPattern",
      rules: {
        required: false,
        pattern: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})",
        customErrorMessage: "Password must contain at least 1 lowercase alphabetical character, 1 uppercase alphabetical character, 1 numeric character, 1 special character and must be 8 characters or longer"
      }
    }]),

    username: "",
    role: "",
    merchants: "",

    confirmPassword: "",
    confirmPasswordTouched: false,
    showSuccess: false,
    showError: false,
    errorMessage: "",
    successMessage: "",
    isLoading: true,
    submitClicked: false,

    //temporary
    currentLanguage: "en"
  };

  componentDidMount() {
    const { accountForm } = this.state;

    crudActions.get(`v1/myaccount`).then(
      (accountData) => {
        if (accountData) {
          this.setState({
            accountForm: Object.assign(accountForm, {
              name: accountData.name,
              phone: accountData.phone,
              skype: accountData.skype
            }),
            username: accountData.username,
            role: accountData.role,
            merchants: accountData.merchants.join(", "),
            isLoading: false
          });
        }
      }
    ).catch(
      (err) => {
        if (err && err.message) {
          this.setState({
            showError: true,
            errorMessage: err.message
          });
        }
      }
    );
  };

  onValueChange = (event, fieldName) => {
    let { accountForm } = this.state;
    const { submitClicked } = this.state;
    accountForm = Object.assign(accountForm, {
      [fieldName]: event.target.value
    });

    if (submitClicked) {
      accountForm.isFormValid();
    }

    this.setState({
      accountForm: accountForm
    });
  };

  onConfirmPasswordChange = (event) => {
    this.setState({
      confirmPassword: event.target.value,
      confirmPasswordTouched: true
    });
  };

  arePasswordsIdentical = () => {
    const { accountForm, confirmPassword } = this.state;

    if (!accountForm.currentPassword && !accountForm.newPassword) {
      return true;
    }

    return accountForm.newPassword === confirmPassword;
  };

  submitAccountSettings = () => {
    const { accountForm } = this.state;
    const isFormValid = accountForm.isFormValid();
    this.setState({
      accountForm: accountForm,
      confirmPasswordTouched: true,
      submitClicked: true
    });

    if (isFormValid && this.arePasswordsIdentical()) {
      crudActions.put(`v1/myaccount`, Object.assign(accountForm.data(), { fullName: accountForm.name })).then(
        () => {
          this.setState({
            showSuccess: true,
            successMessage: "Your data was successfully updated."
          });
        }
      ).catch(
        (err) => {
          if (err && err.message) {
            this.setState({
              showError: true,
              errorMessage: err.message
            });
          }
        }
      );
    }
  };

  onConfirm = () => {
    this.setState({
      showSuccess: false,
      showError: false,
      errorMessage: "",
      successMessage: "",
    });
  };

  render() {
    const { accountForm, confirmPassword, confirmPasswordTouched, username, isLoading,
      role, merchants, showSuccess, showError, errorMessage, successMessage } = this.state;
    const { t } = this.props;

    return (
      <Row flexGrow={ 1 } className="t365 module apidata my-account" vertical='start'>
        <Column flexGrow={ 1 }>
          <ComponentHeader
            title="My Account"
            img={ ReportIcon }
          />
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    USER INFO
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <form autoComplete="off">
                    <input type="password" autoComplete="new-password" style={ { display: "none" } }/>
                    { isLoading ? <div style={ { width: "100%", height: "200px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div> : <div className="panel-content" style={ {overflow: 'unset'} }>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='start'>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Full Name </label>
                            <input
                              className={ "form-control " + (accountForm.errors.has('name') ? 'error-field' : '') }
                              value={ accountForm.name || '' }
                              onChange={ (e) => this.onValueChange(e, "name") }/>
                            { accountForm.errors.has('name') && <p className="error-message"> { accountForm.errors.get('name') } </p> }
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Phone </label>
                            <input
                              className={ "form-control " + (accountForm.errors.has('phone') ? 'error-field' : '') }
                              value={ accountForm.phone || '' }
                              onChange={ (e) => this.onValueChange(e, "phone") }/>
                            { accountForm.errors.has('phone') && <p className="error-message"> { accountForm.errors.get('phone') } </p> }
                          </Column>
                        </Row>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='start'>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Skype </label>
                            <input
                              className={ "form-control " + (accountForm.errors.has('skype') ? 'error-field' : '') }
                              value={ accountForm.skype || '' }
                              onChange={ (e) => this.onValueChange(e, "skype") }/>
                            { accountForm.errors.has('skype') && <p className="error-message"> { accountForm.errors.get('skype') } </p> }
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Email/username </label>
                            <input
                              className="form-control"
                              disabled
                              value={ username || '' }/>
                          </Column>
                        </Row>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <h5 className="change-password-title"> CHANGE PASSWORD </h5>
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='start'>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Current Password </label>
                            <input
                              type="password"
                              className={ "form-control " + (accountForm.errors.has('currentPassword') ? 'error-field' : '') }
                              value={ accountForm.currentPassword || '' }
                              onChange={ (e) => this.onValueChange(e, "currentPassword") }/>
                            { accountForm.errors.has('currentPassword') && <p className="error-message"> { accountForm.errors.get('currentPassword') } </p> }
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> New Password </label>
                            <input
                              type="password"
                              className={ "form-control " + (accountForm.errors.has('newPassword') ? 'error-field' : '') }
                              value={ accountForm.newPassword || '' }
                              onChange={ (e) => this.onValueChange(e, "newPassword") }/>
                            { accountForm.errors.has('newPassword') && <p className="error-message"> { accountForm.errors.get('newPassword') } </p> }
                          </Column>
                        </Row>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='start'>
                          <Column flexGrow={ 1 } vertical='end' alignSelf='start' className="input-column">
                            <label> New Password Again </label>
                            <input
                              type="password"
                              className={ "form-control " + (!this.arePasswordsIdentical() && confirmPasswordTouched ? 'error-field' : '') }
                              value={ confirmPassword || '' }
                              onChange={ (e) => this.onConfirmPasswordChange(e) }/>
                            { !this.arePasswordsIdentical() && confirmPasswordTouched && <p className="error-message">
                              { !confirmPassword ? "Please, confirm the password."  : "Passwords are not identical." } </p>}
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <span/>
                          </Column>
                        </Row>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <h5 className="change-password-title"> ACCESS </h5>
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='start'>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> User Level </label>
                            <input
                              className="form-control"
                              disabled
                              value={ t(role, { framework: "react-i18next" }) || '' }/>
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Merchant </label>
                            <input
                              className="form-control"
                              disabled
                              value={ merchants || '' }/>
                          </Column>
                        </Row>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='start'>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <span/>
                          </Column>
                          <Column flexGrow={ 1 } vertical='end' alignSelf='end' className="input-column">
                            <Button
                              className="btn defaultBtn"
                              onClick={ this.submitAccountSettings }>
                              Save
                            </Button>
                          </Column>
                        </Row>
                      </Row>
                    </div> }
                  </form>
                </Panel.Body>
              </Panel>
            </Column>
          </Row>
        </Column>
        <SweetAlert
          show={ showSuccess }
          title="Success"
          type="success"
          confirmButtonColor="#25282a"
          text={ successMessage }
          onConfirm={ this.onConfirm }
        />
        <SweetAlert
          show={ showError }
          title="Error"
          type="error"
          confirmButtonColor="#25282a"
          text={ errorMessage }
          onConfirm={ this.onConfirm }
        />
      </Row>
    );
  }
}

export default withTranslation("common")(MyAccount);
