import React from 'react';
import '../assets/css/spinner.css';

const spinner = (props) => (
  <div
    className={ props.smallContainer ? 'Loader small' : 'Loader' }
    style={ { color: props.color || '#f19c49' } }
  >
    Loading...
  </div>
);

export default spinner;