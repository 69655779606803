import React, {Component} from 'react';

import '../../../assets/css/customMultiselect.css';

class LastUpdateComponent extends Component {
    state = {
      isMultiselectOpen: false,
      selectedItems: [],
      selectedItemsMap: {},
      searchPattern: ""
    };

    node = null;

    // eslint-disable-next-line react/no-deprecated
    componentWillMount() {
      document.addEventListener("mousedown", this.handleClickOutside, false);
    };

    componentDidMount() {
      this.onNewDataIncome();
    };

    onNewDataIncome = () => {
      const selectedItemsMap = {};
      const { selectedItems, items } = this.props;
      items.forEach(item => {
        const value = item.value;
        if (selectedItems.some(elem => elem.value === value)) {
          selectedItemsMap[value] = true;
        } else {
          selectedItemsMap[value] = false;
        }
      });

      this.setState({
        selectedItems: selectedItems,
        selectedItemsMap: selectedItemsMap
      });
    };

    componentDidUpdate(prevProps) {
      if (this.props.items.length !== prevProps.items.length
          || this.props.isError !== prevProps.isError ||
          this.props.selectedItems.length !== prevProps.selectedItems.length) {
        this.onNewDataIncome();
      }
    }

    componentWillUnmount() {
      document.removeEventListener("mousedown", this.handleClickOutside, false);
    };

    handleClickOutside = (event) => {
      if (this.node && this.node.contains(event.target)) {
        return;
      }

      this.setState({
        isMultiselectOpen: false
      });
    };

    handleSearchChange = (event) => {
      this.setState({
        searchPattern: event.target.value
      });
    };

    getSelectedItems = () => {
      const { withId } = this.props;
      return this.state.selectedItems.map(item => withId ? `${item.method} (${item.value})` : item.label).join(", ");
    };

    getFilteredItems = () => {
      const searchPattern = this.state.searchPattern.toLowerCase();
      const filteredItems = this.props.items.filter(item => {
        const label = item.label.toLowerCase();
        if (label.includes(searchPattern)) {
          return true;
        }

        return false;
      });

      filteredItems.sort((elemA, elemB) => elemA.label.localeCompare(elemB.label));

      return filteredItems;
    };

    updateSelectedMap = (event, value) => {
      const { selectedItems, selectedItemsMap } = this.state;
      const { type, items, onChange } = this.props;
      const isChecked = event.target.checked;
      if (isChecked) {
        selectedItems.push(
          Object.assign({}, items.find(item => item.value === value))
        );
        selectedItemsMap[value] = true;
      } else {
        const removeIndex = selectedItems.findIndex(item => item.value === value);
        selectedItems.splice(removeIndex, 1);
        selectedItemsMap[value] = false;
      }

      onChange(selectedItems, type);

      this.setState({
        selectedItems: selectedItems,
        selectedItemsMap: selectedItemsMap,
      });
    };

    onSelectAllClick = () => {
      const { selectedItems, selectedItemsMap } = this.state;
      const { type, items, onChange } = this.props;
      items.forEach(item => {
        const value = item.value;
        if (selectedItemsMap[value]) {
          return item;
        } else {
          selectedItemsMap[value] = true;
          selectedItems.push(Object.assign({}, item));
        }
      });

      onChange(selectedItems, type);

      this.setState({
        selectedItemsMap: selectedItemsMap,
        selectedItems: selectedItems
      });
    };

    onDeselectAllClick = () => {
      const { selectedItems, selectedItemsMap } = this.state;
      const { type, items, onChange } = this.props;
      items.forEach(item => {
        const value = item.value;
        if (!selectedItemsMap[value]) {
          return item;
        } else {
          selectedItemsMap[value] = false;
          const removeIndex = selectedItems.findIndex(selectedItem => selectedItem.value === item.value);
          selectedItems.splice(removeIndex, 1);
        }
      });

      onChange(selectedItems, type);

      this.setState({
        selectedItemsMap: selectedItemsMap,
        selectedItems: selectedItems
      });
    };

    render() {
      const { withId, disabled } = this.props;

      return <div
        ref={ node => this.node = node }
        className="customMultiselect">
        <div onClick={ () => this.setState({ isMultiselectOpen: !this.state.isMultiselectOpen}) }>
          <input
            disabled={ disabled }
            readOnly={ !disabled }
            style={ { paddingRight: "25px" } }
            className={ "form-control multiselect-results " + (this.props.isError ? "multiselect-error" : "") }
            value={ this.getSelectedItems() || "No items selected." }/>
          <span className={ "select-arrow " + (this.state.isMultiselectOpen ? "select-arrow-open" : "") }>
            <svg
              viewBox="0 0 255 255">
              <g id="arrow-drop-down">
                <polygon points="0,63.75 127.5,191.25 255,63.75"/>
              </g>
            </svg>
          </span>
        </div>
        { this.state.isMultiselectOpen && <div className="multiselect-items">
          <div className="search">
            <input
              type="text"
              placeholder="Search..."
              className="form-control"
              onChange={ (event) => this.handleSearchChange(event) }/>
          </div>
          <div className="select-functionality">
            <div
              className="select-all"
              onClick={ () => this.onSelectAllClick() }>
                  Select All
            </div>
            <div
              className="deselect-all"
              onClick={ () => this.onDeselectAllClick() }>
                  Deselect All
            </div>
          </div>
          <div className="items">
            { this.getFilteredItems().map(item => {
              return <div key={ item.value } className="select-item">
                { withId ? `${item.method} (${item.value})` : item.label }
                <input
                  type="checkbox"
                  name="item"
                  className="custom-checkbox"
                  id={ item.value }
                  checked={ this.state.selectedItemsMap[item.value] }
                  onChange={ (event) => this.updateSelectedMap(event, item.value) }/>
                <label className="select-checkbox" htmlFor={ item.value }/>
              </div>;
            })
            }
          </div>
        </div>
        }
      </div>;
    }

}

export default LastUpdateComponent;