import React, { Component } from 'react';
import Select from "react-select";

class CustomSelect extends Component {
  state = {};

  sortItems = (items) => {
    items.sort((elemA, elemB) => !elemA.prevent_sorting && !elemB.prevent_sorting && elemA.label.localeCompare(elemB.label));

    return items;
  };

  render() {
    const { value, required, clearable, onChange, options, className, disabled, enableSort } = this.props;

    return (
      <Select
        className={ className }
        value={ value }
        disabled={ disabled }
        required={ required }
        clearable={ clearable }
        onChange={ (event) => onChange(event) }
        options={ enableSort === false ? options :  this.sortItems(options) }
      />
    );
  }
}

export default CustomSelect;