import React, {Component} from 'react';

import '../../../../assets/css/apiData.css';
import ComponentHeader from "../../../componentHeader";
import DashboardIcon from '../../../../assets/images/dashboardIcon.png';

class LastUpdateComponent extends Component {
  state = {
    pageTitle: 'Dashboard'
  };

  render() {
    const { pageTitle } = this.state;
    const { lastUpdate } = this.props;

    return (
      <ComponentHeader
        title={ pageTitle }
        lastUpdate={ lastUpdate }
        img={ DashboardIcon }
      />
    );
  }
}

export default LastUpdateComponent;