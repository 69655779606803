import React, { Component } from 'react';
import { Row, Column } from 'simple-flexbox';
import { Panel } from "react-bootstrap";

import ComponentHeader from "../../componentHeader";
import Spinner from '../../Spinner';

import '../../../assets/css/sandbox.css';
import APIIcon from '../../../assets/images/api.png';

import { crudActions } from "../../../services/crudActions";

class API extends Component {
  state = {
    username: "",
    password: "",
    url: "",
    isLoading: true
  };

  componentDidMount() {
    crudActions.get(`v1/docs/clients`).then(
      (docs) => {
        if (docs) {
          this.setState({...docs, isLoading: false});
        }
      }
    );
  };

  render() {
    const { isLoading, url } = this.state;

    return (
      <Row flexGrow={ 1 } className="t365 module sandbox" vertical='start'>
        <Column flexGrow={ 1 }>
          <ComponentHeader
            title={ 'Application Programming Interface' }
            img={ APIIcon }
          />
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    API DOCUMENTATION
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  { isLoading ? <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                    <Spinner smallContainer={ true } />
                  </div> : <div className="panel-content" style={ {overflow: 'unset'} }>
                    <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end'>
                      <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column" style={ { width: "75%" } }>
                        <h4> Click to view the API documentation </h4>
                      </Column>
                      <Column flexGrow={ 1 } vertical='start' alignSelf="center" className="input-column">
                        <a
                          type="submit"
                          className="btn defaultBtn"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={ url || "https://docs.t365.io/" }
                        >
                          VIEW
                        </a>
                      </Column>
                    </Row>
                  </div> }
                </Panel.Body>
              </Panel>
            </Column>
          </Row>
        </Column>
      </Row>
    );
  }
}

export default API;
