import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import { Column } from 'simple-flexbox';

import { menuList } from '../utils/menuList';

import { FETCH_PERMISSIONS } from '../actions/types';
const store =  require('../reducers/index');

class SideBar extends Component {
    state = {
      menu: menuList,
      access: []
    }

    subscribeFunction = null;

    componentDidMount() {
      const storeState = store.default.getState().authReducer;
      if (storeState.access && storeState.roleId) {
        this.setState({
          access: storeState.access,
          roleId: storeState.roleId
        });
      }

      this.subscribeFunction = store.default.subscribe(() => {
        const state = store.default.getState().authReducer;

        if (state.userUpdate === FETCH_PERMISSIONS) {
          this.setState({
            access: state.access,
            roleId: state.roleId
          });
        }
      });
    };

    componentWillUnmount() {
      if (this.subscribeFunction) {
        this.subscribeFunction();
      }
    }

    checkPageAccessCategory = (categoryName) => {
      const { access } = this.state;
      const freeAccessed = ["MY_ACCOUNT", "API"];
      if (freeAccessed.some(elem => elem === categoryName)) {
        return true;
      }

      const foundPermissions = access.filter(elem => elem.category === categoryName);
      return foundPermissions.some(elem => elem.state);
    };

    checkPageAccess = (permissionName) => {
      const { access } = this.state;
      const foundPermission = access.find(elem => elem.permission === permissionName);
      if (!foundPermission) {
        return false;
      }

      return foundPermission.state;
    };

    displayMenuItem = (menu) => {
      const { access } = this.state;
      if (menu.submenu.find(elem => elem.access === "MY_ACCOUNT" || elem.access === "API")) {
        return true;
      }

      const foundItems = access.filter(permission => menu.submenu.some(elem => permission[elem.permission ? "permission" : "category"] === elem.access));
      return foundItems.some(elem => elem.state);
    };

    sortMenu = (menu) => {
      menu.sort((elemA, elemB) => {
        if (elemA.name < elemB.name)
          return -1;
        if (elemA.name > elemB.name)
          return 1;
        return 0;
      });

      return menu;
    };

    render() {

      return (
        <div className="sidebar">
          <div className="list-menus">
            {this.state.menu.map((menu, i) =>
            { return this.displayMenuItem(menu) && <Column key={ i } className="menublock">
              <Column className="header">{menu.header}</Column>
              {
                this.sortMenu(menu.submenu).map((item, j) => {
                  return (item.permission ? this.checkPageAccess(item.access) : this.checkPageAccessCategory(item.access)) &&
                        <NavLink to={ item.url } key={ j } className="menuItem"> {item.name} </NavLink>;
                }
                )}
            </Column>;
            })}
          </div>
        </div>
      );
    }
}

export default SideBar;
