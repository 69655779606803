import React, { Component } from 'react';
import { Row, Column } from 'simple-flexbox';
import { Panel, Button } from "react-bootstrap";
import { saveAs } from 'file-saver';

import ComponentHeader from "../../componentHeader";

import '../../../assets/css/sandbox.css';
import NotesIcon from '../../../assets/images/notes.png';

import { merchant } from "../../../config";
import { crudActions } from "../../../services/crudActions";

class Notes extends Component {
  state = {
    updatedDate: ""
  };

  componentDidMount() {
    crudActions.get(`v1/notes/updated`).then(
      date => {
        if (date) {
          this.setState({
            updatedDate: date.updatedDate
          });
        }
      }
    );
  };

  onDownloadClick = () => {
    crudActions.get(`v1/notes`).then(
      response => {
        if (response) {
          response.blob().then(
            result => {
              saveAs(result, `${merchant}.pdf`);
            }
          );
        }
      }
    ).catch(
      err => {
        this.setState({
          showError: true,
          errorMessage: err.message
        });
      }
    );
  };

  render() {
    const { updatedDate } = this.state;

    return (
      <Row flexGrow={ 1 } className="t365 module sandbox" vertical='start'>
        <Column flexGrow={ 1 }>
          <ComponentHeader
            title={ 'Integration Notes' }
            img={ NotesIcon }
          />
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    DOWNLOAD INTEGRATION NOTES
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <div className="panel-content" style={ {overflow: 'unset'} }>
                    <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end'>
                      <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column" style={ { width: "75%" } }>
                        <h4> Download our PDF file for a better understanding of our Web Application and API documentation. </h4>
                        <h4> Version: { updatedDate || "not uploaded yet" }. </h4>
                      </Column>
                      <Column flexGrow={ 1 } vertical='start' alignSelf="center" className="input-column">
                        <Button
                          type="submit"
                          className="btn defaultBtn"
                          disabled={ !updatedDate }
                          onClick={ () => this.onDownloadClick() }>
                          DOWNlOAD
                        </Button>
                      </Column>
                    </Row>
                  </div>
                </Panel.Body>
              </Panel>
            </Column>
          </Row>
        </Column>
      </Row>
    );
  }
}

export default Notes;
